//Redux-Toolkit
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
//Services
import UserService from '../services/UserService';
//Utils
import { generateBackground } from '../Utils';

//load session data
let parsedSessionData = {};
try {
    const sessionData = sessionStorage.getItem('cmh5p');
    if (sessionData) parsedSessionData = JSON.parse(sessionData);
} catch (err) { }

const initialState = {
    user: null,
    isLoggedIn: false,
    isLTI: false,
    ltiToken: null,
    background: generateBackground(),
    isWelcomed: parsedSessionData?.isWelcomed || false,
    isDev: true
}

/*
    [User]
        id: "727b2b99bc00df49d48e7b8b" //mongodb id (unique)
        email: "creator@test.com"
        name: "Amy Adams"
        password: "$2b$10fdsflJLfMEoCeAsfdsfdsfUOhe/ifdsfdsfd9mJ/Q0MzAfdsafddfds0fjZL."
        role: "creator"
        type: "local"
        canCreateRestricted: false
        canInstallRecommended: false
        canUpdateAndInstallLibraries: false
        accountType: 'lti' | 'local'
*/

export const login = createAsyncThunk(
    'user/login',
    async ({email, password}) => {
        return await UserService.login(email, password);
    }
);

export const getUser = createAsyncThunk(
    'user/getUser',
    async () => {
        return await UserService.getCurrentUser();
    }
);

export const logout = createAsyncThunk(
    'user/logout',
    async () => {
        return await UserService.logout();
    }
);

export const ltiLogin = createAsyncThunk(
    'user/ltiLogin',
    async(token) => {
        console.log('/user/ltiLogin');
        //use lti token to obtain access token
        const res = await UserService.loginLTI(token);
        //attach lti token to response
        res.ltiToken = token;
        //return response (this becomes the payload)
        return res;
    }
)

export const setWelcomed = createAsyncThunk(
    'user/setWelcomed',
    async(isWelcomed) => {
        
        //attempt parsing of session data
        let parsedSessionData = {};
        try {
            //get session data
            const sessionData = sessionStorage.getItem('cmh5p');
            if (sessionData) parsedSessionData = JSON.parse(sessionData);
        } catch (err) { }
        //update session data
        parsedSessionData.isWelcomed = isWelcomed;
        //store updated session data
        sessionStorage.setItem('cmh5p', JSON.stringify(parsedSessionData));
        //return value
        return isWelcomed; //payload
    }
)


export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setDev: (state, action) => {
            state.isDev = action.payload;
        }
    },
    extraReducers: {
        [login.fulfilled]: (state, action) => {
            const { user } = action.payload;
            state.user = user;
            state.isLoggedIn = true;
            state.isLTI = false;
            state.ltiToken = null;
        },
        [login.rejected]: (state) => {
            state.user = null;
            state.isLTI = false;
            state.isLoggedIn = false;
            state.ltiToken = null;
        },
        [logout.fulfilled]: (state) => {
            state.user = null;
            state.isLTI = false;
            state.isLoggedIn = false;
            state.ltiToken = null;
        },
        [logout.rejected]: (state) => {
            state.user = null;
            state.isLTI = false;
            state.isLoggedIn = false;
            state.ltiToken = null;
        },
        [ltiLogin.fulfilled]: (state, action) => {
            state.user = action.payload.user;
            state.isLoggedIn = true;
            state.isLTI = true;
            state.ltiToken = action.payload.ltiToken;
        },
        [ltiLogin.rejected]: (state) => {
            state.user = null;
            state.isLTI = false;
            state.isLoggedIn = false;
            state.ltiToken = null;
        },
        [getUser.fulfilled]: (state, action) => {
            const { user } = action.payload;
            state.user = user;
            state.isLoggedIn = (user != null);
            state.isLTI = (user && user.accountType === 'lti');
            state.ltiToken = null
        },
        [getUser.rejected]: (state) => {
            state.user = null
            state.isLTI = false;
            state.isLoggedIn = false;
            state.ltiToken = null;
        },
        [setWelcomed.fulfilled]: (state, action) => {
            state.isWelcomed = action.payload;
        },
    }
});

export const { setDev, setUser, setToken } = userSlice.actions;

export default userSlice.reducer;