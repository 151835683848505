//React
import { useEffect, useState } from 'react';
//Antd
import { Alert, Button, Col, message, Row, Spin, Space } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
//Utils
import { getSafeLibraryName } from '../Utils';

export const Downloader = (props) => {
    //props
    const { contentService, token, onCancel, currentItem, currentModal } = props;
    //local state
    const [ isLoading, setLoading ] = useState(true);
    const [ downloadLink, setDownloadLink ] = useState(null);

    //generate filename
    const filename = `cm-${getSafeLibraryName(currentItem.mainLibrary)}-${currentItem.id}.h5p`;

    //mount
    useEffect(() => {
        const getDownload = async () => {
            //show loading
            setLoading(true);
            //get download link
            const link = await contentService.download(currentItem.id, token);
            //set download link and filename
            setDownloadLink(link);
            //hide loading
            setLoading(false);
        }
        getDownload();
    }, [currentItem, token, contentService]);

    //close modal after x seconds
    const handleTimeout = (e) => {
        //confirmation started
        message.success(`Starting download...`);
        //set timeout to close modal
        setTimeout(() => {
            onCancel();
        }, 300);
    }
      
    //render
    return (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={isLoading}>
            <Alert
                description={<><strong>Note:</strong> <strong>Custom Made H5P</strong> files (cm-{getSafeLibraryName(currentItem.mainLibrary)}...) can only be re-uploaded to <strong>Custom Made H5P</strong>. They cannot be used within <strong>h5p.com</strong>, <strong>h5p.org</strong> or via <strong>Build Content &gt; Interactive Content - H5P</strong>.</>}
                type="warning"
                showIcon
                closable
                style={{marginBottom: 16}}
            />
            <p>
                <strong>Title:</strong> {currentItem.title}<br />
                <strong>Content ID:</strong> {currentItem.id}<br />
                <strong>Content Type:</strong> {currentItem.contentType} ({currentItem.mainLibrary})<br />
                <strong>Filename:</strong> {filename}
            </p>
            
            <div className="border-top margin-t padding-t">
                <Row justify="end">
                    <Col>
                        <Space>
                            <Button onClick={onCancel}>Cancel</Button>
                            <a onClick={ e => { handleTimeout(currentModal) }} href={downloadLink} target="_blank" download={filename} rel="noreferrer"><Button type="primary" title="Download content item">Download</Button></a>
                        </Space>
                    </Col>
                </Row>
            </div>
        </Spin>
    )
}