class UserService {

    static async login(email, password) {
        console.log(`[UserService] login()`)
        const res = await fetch('https://h5p.westernsydney.edu.au/login', {
            method: 'POST',
            headers: {  
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email, password }),
            credentials: 'include' /* MUST INCLUDE CREDENTIALS */
        });
        //on error
        if (!res || !res.ok) {
            const message = `${res?.status} - ${res?.statusText}`;
            console.error(message);
            throw new Error(message);
        }
        //on success
        const json = await res.json();
        console.log(json);
        return json;
    }

    static async getCurrentUser() {
        console.log(`[UserService] getCurrentUser()`);
        const res = await fetch('https://h5p.westernsydney.edu.au/get-user', {
            method: 'GET',
            headers: {  
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            credentials: 'include' /* MUST INCLUDE CREDENTIALS */
        });
        if (!res || !res.ok) {
            const message = `${res?.status} - ${res?.statusText}`;
            console.error(message);
        }
        //on success
        const json = await res.json();
        console.log(json);
        return json;
    }

    static async loginLTI(token) {
        console.log(`[UserService] loginLTI()`);
        const res = await fetch('https://h5p.westernsydney.edu.au/login-lti', {
            method: 'GET',
            headers: {  
                'Authorization' : `Bearer ${token}`,
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            credentials: 'include' /* MUST INCLUDE CREDENTIALS */
        });
        //on error
        if (!res || !res.ok) {
            const message = `${res?.status} - ${res?.statusText}`;
            console.log(message);
            throw new Error(message);
        }
        //return parsed response
        const json = await res.json();
        console.log(json);
        return json;
    }

    static async logout() {
        console.log(`[UserService] logout()`);
        const res = await fetch('https://h5p.westernsydney.edu.au/logout', {
            method: 'GET',
            credentials: 'include' /* MUST INCLUDE CREDENTIALS */
        });
        //on error
        if (!res || !res.ok) {
            const message = `${res?.status} - ${res?.statusText}`;
            console.error(message);
            throw new Error(message);
        }
        //on success
        const json = await res.json();
        //console.log(json);
        return json;
    }
}

export default UserService;


    