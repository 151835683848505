import { Component } from 'react'
//Redux
import { connect } from 'react-redux';
import { login } from '../reducers/userReducer';
//Router
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
//Google Analytics
import ReactGA from 'react-ga';
//Antd
import { Button, Col, Divider, Form, Input, Modal, message, Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { AiOutlineRight } from "react-icons/ai";
//Icons
import { IoClose } from "react-icons/io5";
//Utils
import { detectIncognito } from "detectincognitojs";
import { getCurrentYear, log } from '../Utils';




class PageLogin extends Component {
  
  constructor(props) {
    super();
    
    this.state = { 
      isLoading: false,
      currentModal: null
    };
  }

  async check() {
    const { isPrivate } = await detectIncognito();

    if ((!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && isPrivate === true) {
      this.setState({currentModal: 'warning-dev-and-incognito'})
    }
  }
  
  async componentDidMount() {
    log(`[PageLogin]`);
    
    /* This page handles both requests for local and LTI login */
    const { isLTI, user } = this.props;

    //check for issues
    this.check();

    //add body class
    document.body.classList.remove(...document.body.classList);
    document.body.classList.add("page-login", "bg-image");
    ReactGA.pageview(window.location.pathname + window.location.search);
    
    //check for LTI login
    if ( isLTI ) {
      //only redirect if user role matches, otherwise do nothing - stay on this page for diagnostic purposes
      if (['creator', 'admin'].includes(user.role)) {
        setTimeout(() => this.props.history.push("/list"), 3000);
      } else {
        console.log('Learner users cannot access editor interface');
      }
    }
  }

  //HANDLERS
  onFormSubmitLoginLocal = async (values) => {
    const { dispatch } = this.props;
    //set as loading
    this.setState({ isLoading : true });
    //attempt login
    const action = await dispatch( login(values) );
    //stop loading
    this.setState({ isLoading : false });
    switch (action.type) {
      case 'user/login/rejected':
        message.error(action.error.message);
        break;
      case 'user/login/fulfilled':
        message.success(`Login successful`);
        this.props.history.push("/list");
        break;
      default:
        message.error('Login failed');
        break;
    }
  };

  //RENDERERS
  renderPanelHeader() {
    return (
      <div className="login-branding">
        <img src="/images/logo-custommadeh5p-full.svg" height={70} alt={process.env.REACT_APP_PRODUCT_NAME} /> 
      </div>
    )
  }

  renderPanelFooter(variant="full") {
    return (
      <>
        { variant === 'full' && <div className="register-info"><Button className="register-info-button" type="link" onClick={() => this.setState({ currentModal: 'register-info' })}>No account? Can't log in?</Button></div> }
        <div className="fineprint">
          <span>
            {process.env.REACT_APP_COMPANY_NAME} 2022-{getCurrentYear()}<br />
            { variant === 'full' && <><Link to="/terms-of-service" title="Read the Terms of Service">Terms of Service</Link> | <Link to="/privacy-policy" title="Read the Privacy Policy">Privacy Policy</Link><br /></> }
            v{process.env.REACT_APP_VERSION}
          </span>
        </div>
      </>
    )
  }

  renderAuthenticationType(type) {
    return (
      <div style={{ textAlign: 'center' }}>
        { type === 'lti' && (<h4>LTI Authentication</h4>) }
        { type === 'local' && (<h4>Local Authentication</h4>) }
      </div>
    );
  }

  renderLoginLTI() {
    const { user } = this.props;
    return (
        <Row justify="center" align="middle">
          <Col xs={24}>
              
              <div className="login-container" style={{margin: '30px auto 0 auto'}}>

                { this.renderPanelHeader() }

                { this.renderAuthenticationType('lti') }

                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={true}>
                  <Form
                    name="login"
                    initialValues={user}
                    layout="vertical"
                    onFinish={this.onFormSubmitLoginLTI}
                  >
                    <Form.Item label="Name" name="name">
                      <Input readOnly disabled/>
                    </Form.Item>

                    <Form.Item label="Email" name="email">
                      <Input readOnly disabled/>
                    </Form.Item>

                    <Form.Item label="Role" name="role">
                      <Input readOnly disabled/>
                    </Form.Item>
                    
                  </Form>
                </Spin>

                { this.renderPanelFooter('lite') }
                
              </div>
            
          </Col>
        </Row>
    )
  }

  renderLoginLocal() {
    const { isLoading } = this.state;
    return (
      <Row justify="center" align="middle">
        <Col xs={0} sm={0} md={0} lg={8}>

          <div className="left-panel">
            <div className="left-panel-header">
              <h1>welcome.</h1>
              <h2>get started creating</h2>
            </div>
            
            <Row justify="center" align="middle">
              <Col sm={20}>
                <div className="left-panel-footer">
                  <h3 className="footer-heading">resources</h3>
                  <ul>
                    <li><AiOutlineRight className="footer-link-icon" /> <a href="https://lf.westernsydney.edu.au/engage/technology/h5p" target="_blank" rel="noreferrer" title="Learn how to create official H5P content">Interactive Content - H5P</a></li>
                    <li><AiOutlineRight className="footer-link-icon" /> <a href="https://lf.westernsydney.edu.au/engage/" target="_blank" rel="noreferrer" title={`Learn more about ${process.env.REACT_APP_PRODUCT_NAME} content types on The Hub`}>The Hub</a></li>
                    <li><AiOutlineRight className="footer-link-icon" /> <a href="https://h5p.com/" target="_blank" rel="noreferrer" title="Check out the official H5P.com website and resources">H5P.com</a></li>
                  </ul>
                </div>
              </Col>
            </Row> 
          </div>
        </Col>
        
        <Col xs={24} sm={24} md={24} lg={16}>

          <div className="right-panel">
              <Row justify="center" align="middle">
                <Col xs={24}>

                  
                    <div className="login-container">
                      
                      { this.renderPanelHeader() }

                      { this.renderAuthenticationType('local') }

                      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={isLoading}>
                        <Form
                          name="login"
                          onFinish={this.onFormSubmitLoginLocal}
                          layout="vertical"
                        >
                          <Form.Item
                            label="Email"
                            name="email"
                            tooltip="Enter your Western email address"
                            rules={[{ required: true, type: "email", message: 'Please input your email address.' }]}
                          >
                            <Input placeholder="12345678@westernsydney.edu.au" />
                          </Form.Item>

                          <Form.Item
                            label="Password"
                            name="password"
                            tooltip="This password is unique to Custom Made H5P and is not your WesternAccount password"
                            rules={[{ required: true, message: 'Please input your password.' }]}
                          >
                            <Input.Password />
                          </Form.Item>

                          <Form.Item>
                            <Button disabled={isLoading} type="primary" block htmlType="submit" title="Login">Login</Button>
                          </Form.Item>

                        </Form>
                      </Spin>

                      { this.renderPanelFooter() }

                    </div>
                  
                </Col>
              </Row>
              
          </div>
        </Col>
      </Row> 
    )
  }

  

  //MODALS
  renderCurrentModal() {
    const { currentModal } = this.state;
    switch (currentModal) {
      case 'warning-dev-and-incognito':
        return (
          <Modal 
            title="Developing in incognito mode is unsupported" 
            visible={true}
            footer={null}
            centered={true}
            closable={false}
          >
            <p>During development, please use normal browser windows.</p>
            <p>Incognito mode <em>(or private browsing)</em> is <strong>NOT SUPPORTED</strong> during development as <strong>httpOnly</strong> cookies cannot be read cross-origin.</p>
            <p>Incognito mode <strong>IS SUPPORTED</strong> once deployed to hosting.</p>
            <p>To proceed, reload this page in a normal browser window.</p>
          </Modal>
        )
      case 'register-info':
        return (
          <Modal 
            title="Registration Information" 
            visible={true}
            onCancel={() => this.setState({ currentModal: null })}
            footer={null}
            centered={true}
            closeIcon={<IoClose/>}
          >
            { this.renderRegisterInfoModal() }
          </Modal>
        )
      default: 
        return null;
    }
  }

  renderRegisterInfoModal() {
    return (
      <div className="modal-register-info-contents">
        <h3>New users</h3>
        <p>Western staff should access <strong>{process.env.REACT_APP_PRODUCT_NAME}</strong> through a Learning Management System (LMS) such as Blackboard (vUWS), instead of this direct access page.</p>
        <p>If you are a current employee of Western Sydney University but don't have LMS access, you can either:</p>
        <ul>
          <li>Request access to the LMS which automatically provides you with <strong>full access</strong> to <strong>{process.env.REACT_APP_PRODUCT_NAME}</strong> <em>(preferred)</em></li>
          <li>Contact <a href="mailto:learningfutures@westernsydney.edu.au?subject=Custom Made H5P - Request staff access" target="_blank" rel="noreferrer" subject="">Learning Futures</a> to arrange direct, <strong>feature limited</strong> access</li>
        </ul>
        <Divider />
        <h3>Existing users</h3>
        <p>Western staff with access to a Learning Management System (LMS) such as Blackboard (vUWS) will receive the message <em>"User account not registered for direct access"</em> when attempting to login via this page.</p>
        <p>If you have already used <strong>{process.env.REACT_APP_PRODUCT_NAME}</strong> through a LMS, please continue to use the LMS to access this platform.</p>
      </div>
    )
  }

  //RENDER
  render() {
    return (
      <div className="body-general">
        { this.props.isLTI ? this.renderLoginLTI() : this.renderLoginLocal() }
        { this.renderCurrentModal() }
      </div>
    )
  }

  
}



function mapStateToProps(state) {

  //console.log(state);

  return {
    isLTI: state.user.isLTI,
    token: state.user.token,
    user: state.user.user
  }
}

export default withRouter(connect(mapStateToProps)(PageLogin))