import React from 'react'
//Router
import { Link } from 'react-router-dom';
//React-Router
import { withRouter } from "react-router";
//Google Analytics
import ReactGA from 'react-ga';
//H5P Components
import { H5PPlayerUI } from '@lumieducation/h5p-react';
//Antd
import { Button, Col, Row, Spin } from 'antd';
import { EditOutlined, EyeOutlined, FolderAddOutlined, LoadingOutlined } from '@ant-design/icons';
//Redux-Toolkit
import { connect } from 'react-redux';
//Components
import { Error } from './Error';
import { Watermark } from './Watermark';
//Utils
import { banner, log } from '../Utils';
//Services
import { StatisticsService } from '../services/StatisticsService';

class PagePreview extends React.Component {

  constructor(props) {
    super();
    log(`[PagePreview]`);
    //editor ref
    this.h5pEditorRef = React.createRef();
    //state
    this.state = {
      error: null,
      isLoading: true,
      playerData: null,
      title: ''
    }
  }

  //mount
  async componentDidMount() {
    //get id
    const { id } = this.props.match.params;

    //get content service
    const { contentService } = this.props; 

    //apply body class
    document.body.classList.remove(...document.body.classList);
    document.body.classList.add("page-preview", "bg-image");

    //google analytics
    ReactGA.pageview(window.location.pathname + window.location.search);

    try {
      //retrieve player data
      const playerData = await contentService.getPlay(id, this.props.token);
      //get title from player data
      const title = playerData.integration?.contents[`cid-${id}`]?.metadata?.title || '';
      //store player data and title in local state
      this.setState({
        isLoading: false,
        playerData, 
        title
      });
    } catch (err) {
      this.setState({ 
        error: err.message, 
        isLoading: false
      });
    }

    //show banner in console
    banner();
  }

  //renderers
  renderTitle() {
    const { title } = this.state;
    return (
      <div className="item-title-container">
        <Row>
          <Col>
            <h1>Viewing: {title}</h1>
          </Col>
        </Row>
      </div>
    )
  }

  //render
  render() {
    //get id
    const { id } = this.props.match.params;

    //get is loading and error local state
    const { isLoading, error } = this.state;

    //render error
    if (error != null) return (<Error message={error} />);

    //render
    return (
      <>
        <Row justify="center">
          <Col xs={24} sm={22} md={22} lg={18} xl={16}>
            <div className="panel-body">

              <div className="actionbar">
                <Row justify="space-between" align="middle">
                  <Col>
                    <div className="actionbar-links">
                      <Link to="/list">
                        <Button className="margin-r actionbar-link hide-on-mob" type="text" title="See content list" icon={<FolderAddOutlined />}>List</Button>
                        <Button className="margin-r actionbar-link show-on-mob" type="text" title="See content list" size="large" icon={<FolderAddOutlined />} />
                      </Link>
                      <Link to={`/preview/${id}`}>
                        <Button className="margin-r actionbar-link actionbar-link-active hide-on-mob" type="text" title="Preview content" icon={<EyeOutlined />}>View</Button>
                        <Button className="margin-r actionbar-link actionbar-link-active show-on-mob" size="large" type="text" title="Preview content" icon={<EyeOutlined />} />
                      </Link>
                      <Link to={`/edit/${id}`}>
                        <Button className="margin-r actionbar-link hide-on-mob" type="text" title="Edit content" icon={<EditOutlined />}>Edit</Button>
                        <Button className="margin-r actionbar-link show-on-mob" type="text" title="Edit content" size="large" icon={<EditOutlined />} />
                      </Link>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="cm-h5p-container">
                <div className="cm-h5p-inner-container">
                  { this.renderTitle() }
                  { /* If loading, render spinner */ }
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={isLoading}>
                  
                  { /* If not loading, render player */ }
                  { (!isLoading) && (
                    <div className="h5p-player-container">
                      <Watermark />
                      <H5PPlayerUI
                        ref={this.h5pEditorRef}
                        //get withRouter match params id and use as content type id for URL path
                        contentId={id}
                        loadContentCallback={ (contentId) => this.state.playerData }
                        onInitialized={this.onPlayerInitialized}
                        onxAPIStatement={(statement, context, event) => {
                          //get user
                          const { user } = this.props;
                          //send statistics event
                          StatisticsService.addEvent(id, 'xAPI', user, {
                            statement,
                            context,
                            event
                          });
                        }}
                      />
                    </div>
                  )}
                  </Spin>
                </div>
              </div>

            </div>
          </Col>
        </Row>
      </>
    )
  }

  //handlers
  onPlayerInitialized = (contentId) => {
    log(`[PagePreview] onPlayerInitialized`);
  };
}

//global state to props
const mapStateToProps = (state) => ({
  token: state.user.token,
  user: state.user
});

//Wrap page in withRouter so it picks up content type id for props
export default withRouter( connect(mapStateToProps)(PagePreview) );