//Luxon
import { DateTime } from 'luxon';
import { store } from './store'

//Format date for items list
export const formatModifiedDate = (date) => {
  return DateTime.fromISO(date).toFormat('dd LLL yyyy');
}

//Format date for statistics event list
export const formatStatisticsDate = (date) => {
  return DateTime.fromISO(date).toFormat('dd-MM-yyyy HH:mm');
}

//Get current date
export const getCurrentDateStamp = () => {
  return DateTime.now().toFormat('dd-MM-yyyy HH:mm')
}

export const getSafeLibraryName = (mainLibrary) => {
  return mainLibrary.replace(/[\W_]+/g,"-").toLowerCase() || '';
}

//Get current year for footer
export const getCurrentYear = () => {
  return DateTime.now().toFormat('yyyy')
}

//Truncate text to maxChars, unless unique specified use default value
export const truncate = (str, maxChars=65) => {
  if(!str) return "";
  return (str.length > maxChars) ? str.substring(0, maxChars) + "..." : str;
}

//Uppercase first character
export const formatToTitleCase = (str) => {
  return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
}

//Is LTI?
export const isLTI = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.has('ltik');
}

//Get LTI Key
export const getLTIKey = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get('ltik');
}

//Get user from local storage
export const getUserFromLocalStorage = () => {
  //get user as string
  const user = localStorage.getItem('user');
  //if user not set, return null
  if (user == null) return null;
  try {
      return JSON.parse(user);
  } catch (err) {
      return null;
  }
}

//Decode HTML 
export const escapeHtml = (text) => {
  const map = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#039;': "'"
  };
  return text.replace(/&amp;|&lt;|&gt;|&quot;|&#039;/g, function(m) {return map[m];});
}

//Show banner in console
export const banner = () => {
  try {
    console.log('\r\n');
    console.log(
      "%cCustom Made H5P",
      "color: white; font-size: 24px; background-color: #990033; padding: 20px 116px 20px 116px;",
    );
    console.log(
      "%chttps://lf.westernsydney.edu.au/engage/technology/custom-made-h5p/",
      "font-size: 11px;",
    );
    console.log('\r\n');
  } catch(err) {}
}

export const log = (...data) => {
  //only log if in dev mode
  if (store.getState().user.isDev) console.log(...data);
}

export const generateBackground = () => {
  return `/images/bg/${new Date().getDay()}.jpg`; 
}