export class SharingService {

    static async getSharableUsers() {
        console.log(`[SharingService] getSharableUsers()`);
        //fetch
        const res = await fetch(`https://h5p.westernsydney.edu.au/user/role/creator`, {
            method: 'GET',
            credentials: 'include'
        });
        //parse
        return await res.json();
    }

    static async getSharedUsers(id) {
        console.log(`[SharingService] getSharedUsers(${id})`);
        //fetch
        const res = await fetch(`https://h5p.westernsydney.edu.au/h5p/share/${id}`, {
            method: 'GET',
            credentials: 'include'
        });
        //parse
        const body = await res.json();
        return body.sharing;
    }

    static async setSharedUsers(id, users) {
        console.log(`[SharingService] setSharedUsers(${id}, ${users})`);
        //fetch
        const res = await fetch(`https://h5p.westernsydney.edu.au/h5p/share/${id}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({users}),
            credentials: 'include'
        });
        //parse
        return await res.json();
    }
}



    