import React from 'react'
//React-Router
import { withRouter } from "react-router";
//Google Analytics
import ReactGA from 'react-ga';
//H5P Components
import { H5PPlayerUI } from '@lumieducation/h5p-react';
//Redux-Toolkit
import { connect } from 'react-redux';
//Antd
import { Spin, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
//LTI Login
//import { ltiLogin } from '../reducers/userReducer';
//import { isLTI, getLTIKey } from '../Utils';
//Components
import { Error } from './Error';
//Utils
import { banner, log } from '../Utils';
import { Watermark } from './Watermark';
//Services
import { StatisticsService } from '../services/StatisticsService';

class PagePlay extends React.Component {
  constructor(props) {
    super();
    //console.log('[PagePlay]');
    this.h5pEditorRef = React.createRef();
    this.state = {
      id: null,
      isLoading: true,
      error: null,
      user: null,
    }
    //add body class
    document.body.classList.remove(...document.body.classList);
    document.body.classList.add("page-embed", "bg-none");
  }
  async componentDidMount() {
    //get dispatch
    const { contentService, isLTI, user } = this.props; //{ dispatch }
    //get id
    const { id } = this.props.match.params;
    //analytics
    ReactGA.pageview(window.location.pathname + window.location.search);
    //check id
    if (id) {
      //store id in state
      this.setState({ id });
    } else {
      //set error state
      this.setState({
        isLoading: false,
        error: `Request missing content ID`
      });
      //don't proceed further (i.e. render <Error /> component)
      return;
    } 

    //check for LTI login
    if ( isLTI ) {
      console.log('[PagePlay] LTI login detected');
      const username = user.name || 'Anonymous';
      //display success message
      message.success(`Authenticated as: ${username}`);
      //attempt LTI login
      //const action = await dispatch( ltiLogin( getLTIKey() ) );
      //simulate error
      //action.type = 'user/ltiLogin/rejected';
      /*switch (action.type) {
        case 'user/ltiLogin/rejected':
          //log error
          console.error(action.error?.message);
          //set state
          this.setState({ 
            error: `Authentication error: ${ action.error?.message }`
          });
          break;
        case 'user/ltiLogin/fulfilled':
          //get username
          const username = action.payload.user.name || 'Anonymous';
          //display success message
          message.success(`Authenticated as: ${username}`);
          //set state
          this.setState({ 
            error: null
          });
          break;
        default:
          //set state
          this.setState({ 
            error: 'An unknown error occured'
          });
          break;
      }
      */
    }

    //pre-retrieve player data
    try {
      const playerData = await contentService.getPlay(id, this.props.token);
      this.setState({ playerData, isLoading: false })
    } catch (err) {
      this.setState({ error: err.message, isLoading: false })
    }

    //show banner in console
    banner();
  }


  render() {
    
    const { id, error, isLoading, playerData } = this.state;

    //render error
    if (error != null) return (<Error message={error} />);
    
    //render item (with loading wrapper)
    return (
      <div className="cm-h5p-container">
        
        { /* If loading, render spinner */ }
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={isLoading}>
                
        { /* If not loading, render player */ }
        { (!isLoading) && (
          <div className="h5p-player-container">
            <Watermark />
            <H5PPlayerUI
              ref={this.h5pEditorRef}
              //get withRouter match params id and use as content type id for URL path
              contentId={id}
              loadContentCallback={ (contentId) => playerData }
              onInitialized={this.onPlayerInitialized}
              onxAPIStatement={(statement, context, event) => {
                //get user
                const { user } = this.props;
                //send statistics event
                StatisticsService.addEvent(id, 'xAPI', user, {
                  statement,
                  context,
                  event
                });
              }}
            />
          </div>
        )}
        </Spin>
      </div>
    )
  }
  
  onPlayerInitialized = (contentId) => {
    log(`[PagePlay] onPlayerInitialised`);
  };
}

const mapStateToProps = (state) => ({
  token: state.user.token,
  user: state.user,
  isLTI: state.user.isLTI
});

//Wrap page in withRouter so it picks up content type id for props
export default withRouter( connect(mapStateToProps)(PagePlay) );