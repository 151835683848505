import React from 'react'
//React-Router
import { Link } from 'react-router-dom';
//Antd
import { Col, Row } from 'antd';

class Footer extends React.Component {
  render() {

    return (
      <div className="site-footer">
        <Row justify="space-between">
          <Col>
            <small>{process.env.REACT_APP_COMPANY_NAME}</small>
          </Col>
          <Col>
            <small><Link to="/terms-of-service" title="Read the Terms of Service">Terms of Service</Link> | <Link to="/privacy-policy" title="Read the Privacy Policy">Privacy Policy</Link> | <Link to="/open-source" title="Open Source">Open Source</Link> | Version {process.env.REACT_APP_VERSION}</small>
          </Col>
        </Row>
      </div>
    )
  }
}
export default Footer;