//React
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
//Antd
import { BackTop, message } from 'antd';
//Private route
import { PrivateRoute } from './components/PrivateRoute';
//Google Analytics
import ReactGA from 'react-ga';
//Redux
import { connect } from 'react-redux';
//Services
import { ContentService } from './services/ContentService';
//Components
import Header from './components/Header';
import Footer from './components/Footer';
import { Error } from './components/Error';
//Pages
import PageList from './components/PageList';
import PageLogin from './components/PageLogin';
import PageNew from './components/PageNew';
import PageEdit from './components/PageEdit';
import PageClone from './components/PageClone';
import PagePreview from './components/PagePreview'; //Preview within authoring UI
import PagePlay from './components/PagePlay'; //Insert via LTI
import PageEmbed from './components/PageEmbed'; //Embed via iframe
import PageStatistics from './components/PageStatistics';
//Pages (Static)
import PagePrivacyPolicy from './components/PagePrivacyPolicy';
import PageOpenSource from './components/PageOpenSource';
import PageTermsOfService from './components/PageTermsOfService';

import { StatisticsService } from './services/StatisticsService';


//import PageGrades from './components/PageGrades';
//import PageNamesRoles from './components/PageNamesRoles';
//import PageLTILaunch from './components/PageLTILaunch';

//import LibraryAdmin from './components/LibraryAdminComponent';

//Styles
import './App.less';
import { getUser } from './reducers/userReducer';

class App extends React.Component {

    private contentService:any;

    state = {
        isLoading: true
    }

    constructor(props) {
        super(props);

        //Google Analytics
        ReactGA.initialize("UA-113017641-26", { debug:false });
        //configure content service
        this.contentService = new ContentService('https://h5p.westernsydney.edu.au/h5p');
        //this.contentService = new ContentService('http://localhost/h5p');

        StatisticsService.BACKEND = "https://h5p.westernsydney.edu.au";

        //configure messages globally
        message.config({
            top: 40,
            duration: 2,
            maxCount: 1
        });
    }
    async componentDidMount() {
        const { dispatch } = this.props as any;
        document.body.style.backgroundImage = `url(${(this.props as any).background})`;
        //Check for existing login
        console.log('[App] Checking for existing user');
        const action = await dispatch( getUser() );
        switch (action.type) {
            case 'user/getUser/rejected':
                //console.log('Not able to authenticate existing user. Session may have expired.')
                //message.error(action.error.message);
                break;
            case 'user/getUser/fulfilled':
                const { user } = action.payload;
                if (user) {
                    console.log('Existing user found. Session restored!')
                    //message.success(`Session restored!`);
                }
                break;
            default:
                //message.error('Login failed');
                break;
        }
        //stop loading
        this.setState({ isLoading: false });

        //Google Analytics
        ReactGA.pageview(window.location.pathname + window.location.search);
    }
    render() {
        //show nothing if loading
        if (this.state.isLoading) return null;
        //return ui
        return (
            <div className="App">
                <BrowserRouter>
                    <Switch>
                        
                        { /* Insert via LTI */ }
                        <Route path="/play/:id">
                            <PagePlay contentService={this.contentService} className="no-bg" />
                        </Route>

                        <Route exact path="/">
                            <PageLogin />
                        </Route>

                        { /* Login via LTI */ }
                        <Route path="/deeplink">
                            <PageLogin />
                        </Route>


                        <Route path="/nolti">
                            <Error />
                        </Route>
                        <Route path="/statistics/:id">
                            <Header />
                            <PageStatistics contentService={this.contentService} />
                            <Footer />
                        </Route>
                        <PrivateRoute path="/list">
                            <Header />
                            <PageList contentService={this.contentService} />
                            <Footer />
                        </PrivateRoute>
                        <PrivateRoute path="/new">
                            <Header />
                            <PageNew contentService={this.contentService} />
                            <Footer />
                        </PrivateRoute>
                        <PrivateRoute path="/edit/:id">
                            <Header />
                            <PageEdit contentService={this.contentService} />
                            <Footer />
                        </PrivateRoute>
                        <PrivateRoute path="/clone/:id">
                            <Header />
                            <PageClone contentService={this.contentService} />
                            <Footer />
                        </PrivateRoute>

                        { /* Preview within authoring UI */ }
                        <PrivateRoute path="/preview/:id">
                            <Header />
                            <PagePreview contentService={this.contentService} />
                            <Footer />
                        </PrivateRoute>

                        

                        { /* Embed via iframe */ }
                        <Route path="/embed/:id">
                            <PageEmbed contentService={this.contentService} className="no-bg" />
                        </Route>

                        { /* Embed via iframe */ }
                        <Route path="/embed">
                            <PageEmbed contentService={this.contentService} className="no-bg" />
                        </Route>

                        { /*
                            <Route path="/grades">
                                <PageGrades />
                            </Route>
                            <Route path="/namesandroles">
                                <PageNamesRoles />
                            </Route>
                            <PrivateRoute path="/admin">
                                <Header />
                                <LibraryAdmin endpointUrl="./h5p/libraries" />
                                <Footer />
                            </PrivateRoute>
                        */ }

                        <Route path="/privacy-policy">
                            <PagePrivacyPolicy />
                        </Route>
                        <Route path="/terms-of-service">
                            <PageTermsOfService />
                        </Route>
                        <Route path="/open-source">
                            <PageOpenSource />
                        </Route>
                        <PrivateRoute path="/">
                            { /* console.log('HIT LAST ROUTE') */ }
                            <Header />
                            <PageList contentService={this.contentService} />
                            <Footer />
                        </PrivateRoute>
                    </Switch> 
                </BrowserRouter>
                <BackTop className="btn-back-to-top" visibilityHeight={100} />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
      user: state.user.user,
      background: state.user.background
    }
  }


export default connect(mapStateToProps)(App);