import React from 'react';
//Router
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
//Antd
import { Avatar, Button, Col, message, Row, Tooltip } from 'antd';
import { FiLogOut } from 'react-icons/fi';
import { IoPersonSharp } from 'react-icons/io5';

//Utils
import { truncate } from '../Utils';
//Redux
import { connect } from 'react-redux';
import { logout } from '../reducers/userReducer';

class Header extends React.Component {

  render() {
    //console.log(`[Header]`);
    
    if (!this.props.user) return null;

    const { user, isLTI } = this.props.user;

    return (
      <>
        <div className="site-header">
          <Row justify="space-between" align="middle">
            <Col>
              <div className="actionbar-links">
                <Link to="/list">
                  <img className="branding-strap" src="/images/logo-wsu-h5p-strap.svg" height={35} alt={process.env.REACT_APP_PRODUCT_NAME} title="View content list"/>
                </Link>
              </div>
            </Col>
            <Col>
              <div className="actionbar-controls">
                <Row gutter={[5,0]} align="middle">
                  <Col>
                    <span className="hide-on-mob"><Avatar style={{ backgroundColor: '#757575' }} icon={<IoPersonSharp  />} /></span>
                  </Col>
                  <Col>
                    <Tooltip title={<div>{user?.name}<br />{user?.email}</div>} placement="bottom">
                      <div className="user-container">
                        <span className="small-line-height user-name">{truncate(user?.name, 20)}</span>
                        <span className="small-line-height"><small className="lighter-text">{truncate(user?.email, 20)}</small><br />
                        <small className="lighter-text">{this.capitalize(user?.role)}</small></span>
                      </div>
                    </Tooltip>
                  </Col>
                  <Col>
                    { !isLTI && <Button className="margin-l" icon={<FiLogOut />} onClick={e => { this.handleLogout(e) }} type="text" title="Logout" /> }
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
  //capitalise first letter of user role
  capitalize(str='') {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  handleLogout(e) {
    const { dispatch, isLTI } = this.props;
    //only logout if not in LTI mode
    if (!isLTI) {
      dispatch( logout() );
      message.success('Logout successful!');
      this.props.history.push("/");
    }
  }
}
//Wrap page in withRouter so it picks up content type id for props
export default withRouter(connect(state => state)(Header))