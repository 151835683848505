import { useEffect, useState } from 'react'
//React-Router
import { Link, useParams } from 'react-router-dom';
//Google Analytics
import ReactGA from 'react-ga';
//Services
import { StatisticsService } from '../services/StatisticsService';
//Antd
import { Alert, Button, Col, Input, Modal, Row, Table, Tooltip } from 'antd';
//React-Icons
import { IoClose } from "react-icons/io5";
import { BarChartOutlined, EditOutlined, EllipsisOutlined, EyeOutlined, FolderAddOutlined, LoadingOutlined } from '@ant-design/icons';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { BsCodeSlash } from 'react-icons/bs';
//JSON View
import ReactJson from 'react-json-view'
//Utils
import { log, formatStatisticsDate, formatToTitleCase } from '../Utils';

const PageStatistics = (props) => {

  //get contentId
  const { id } = useParams();
  //local state
  const [ isLoading, setLoading ] = useState(true);
  const [ statistics, setStatistics ] = useState(null);
  const [ currentEvents, setCurrentEvents ] = useState([]);
  const [ currentDetails, setCurrentDetails ] = useState(null);

  useEffect(() => {
    //mount
    log(`[PageStatistics]`);
    //add body classes
    document.body.classList.remove(...document.body.classList);
    document.body.classList.add("page-statistics", "bg-image");
    //set as loading (should be true by default)
    setLoading(true);
    //start loading data
    const loadData = async _ => {
      //load data
      const data = await StatisticsService.getEvents(id);
      console.log(data);      
      //set state
      setStatistics(data);
      setCurrentEvents(data.events);
      setLoading(false);
      
    }
    loadData();
    //register page view
    ReactGA.pageview(window.location.pathname + window.location.search);
    return () => { 
      //unmount
    }
  }, [ id ]);

  const { Search } = Input;
  //Table search
  const onChangeSearchVal = (e) => {
    const searchVal = e.target.value.trim().toLowerCase();
    //if no search keywords entered reset table to display all entries
    if(searchVal.length <= 0) {
      setCurrentEvents(statistics.events);
      return;
    }
    //add found entry to new array
    const filteredEvents = statistics.events.filter((event) => {
      return event.user.toLowerCase().includes(searchVal);
    })
    setCurrentEvents(filteredEvents);
  }

  //renderers
  const renderLoading = () => <LoadingOutlined style={{ fontSize: 24 }} spin />
  const renderContent = () => {
    //get statistics
    const { numViews, numxAPI, numEdits } = statistics;
    //column template
    const columns = [
      {
        title: 'Timestamp',
        dataIndex: 'createdAt',
        key: 'createdAt',
        defaultSortOrder: 'descend',
        sortDirections: ['descend','ascend','descend'],
        sorter: {
          compare: (a, b) => a.createdAt.localeCompare(b.createdAt),
        },
        render: (text, item) => formatStatisticsDate(item.createdAt)                 
      },
      {
        title: 'User',
        dataIndex: 'user',
        key: 'user',
        sortDirections: ['descend','ascend','descend'],
        sorter: {
          compare: (a, b) => a.user.localeCompare(b.user),
        }
      },
      {
        title: 'Event Type',
        dataIndex: 'type',
        key: 'type',
        sortDirections: ['descend','ascend','descend'],
        sorter: {
          compare: (a, b) => a.type.localeCompare(b.type),
        },
        render: (text, item) => {
          if(item.type === 'edit') {
            return <div><span className="hide-on-mob">{`${formatToTitleCase(item.type)}`}</span><Tooltip title={`${formatToTitleCase(item.type)}`}><EditOutlined className="show-on-mob" style={{ fontSize: '130%'}} /></Tooltip></div>
          } else if(item.type === 'view') {
            return <div><span className="hide-on-mob">{`${formatToTitleCase(item.type)}`}</span><Tooltip title={`${formatToTitleCase(item.type)}`}><EyeOutlined className="show-on-mob" style={{ fontSize: '130%'}} /></Tooltip></div>
          } else if(item.type === 'xAPI') {
            return <div><span className="hide-on-mob">{item.type}</span><Tooltip title={item.type}><BsCodeSlash className="show-on-mob" style={{ fontSize: '130%'}} /></Tooltip></div>
          }
        }
      },
      {
        title: 'Details',
        dataIndex: 'details',
        key: 'details',
        render: (text, item) => 
          <div>
            <Button disabled={isLoading} onClick={e => setCurrentDetails(item)} className="hide-on-mob" title="View more information">Details</Button>
            <Button disabled={isLoading} onClick={e => setCurrentDetails(item)} className="show-on-mob" title="View more information" icon={<EllipsisOutlined size="large"/>}></Button>
          </div>
      },
    ]
    //render
    return (
      <div>
        {/* Statistics summary */}
        <Alert
            description={
                <>
                    <strong>Note:</strong> Learner attempt information is currently not recorded for content items deployed via embed code, however this may be enabled for Blackboard in the future.
                </>
            }
            type="warning"
            showIcon
            style={{marginBottom: 16}}
            closable
        />
        <div className="item-title-container">
          <Row>
            <Col>
              <h2>Summary</h2>
            </Col>
          </Row>
        </div>
        {/*<p>Content id: {id}</p>*/}
        <div className="statistics-container">
          <Row justify='space-between'>
            <Col xs={24} sm={8}>
              <div className="statistic-block">
                <span className="statistic-title">Views <Tooltip title="Total number of views of the item by Creators and Learners."><AiOutlineInfoCircle /></Tooltip></span>
                <span className="statistic-result">{numViews || 0}</span>
              </div>
            </Col>
            <Col xs={24} sm={8}>   
              <div className="statistic-block"> 
                <span className="statistic-title">xAPI Events <Tooltip title="Total number of xAPI events sent from the item by Creators and Learners. Note: Not all items send xAPI events."><AiOutlineInfoCircle /></Tooltip></span>
                <span className="statistic-result">{numxAPI || 0}</span>
              </div>
            </Col>
            <Col xs={24} sm={8}>  
              <div className="statistic-block">
                <span className="statistic-title">Edits <Tooltip title="Total number of edits made to the item by the Creator and those it has been shared with."><AiOutlineInfoCircle/></Tooltip></span>
                <span className="statistic-result">{numEdits || 0}</span>
              </div>
            </Col>
          </Row>
        </div>
        {/* Statistics details */}
        <div className="item-title-container">
          <Row>
            <Col>
              <h2>Detailed events</h2>
            </Col>
          </Row>
        </div>
        <Search
          onChange={onChangeSearchVal}
          placeholder="Search Users"
          enterButton
          allowClear
          style={{ position: "sticky", top: "0", left: "0" }}
        />
        <Table 
          rowKey="_id"
          columns={columns}
          dataSource={currentEvents} 
          pagination={{
            defaultPageSize: 10, 
            total: currentEvents.length,
            showTotal: total => `Total ${total} result${(total > 1) ? 's' : ''}`,
            pageSizeOptions:["100", "50", "10"],
            showSizeChanger: true,
            hideOnSinglePage: false
          }}
          style={{backgroundColor: "white !important"}}
        />
      </div>
    )
  }
  const renderDetails = () => {
    if (!currentDetails) return null;
    return (
      <Modal 
        title="Details" 
        visible={true} 
        okText="Close"
        onCancel={e => setCurrentDetails(null)} 
        width={720} 
        keyboard={true} 
        closable={true}
        closeIcon={<IoClose/>}
        footer={null}
      >
        <ReactJson src={currentDetails.data} />
      </Modal>
    )
  } 

  //render
  return (
    <>
      <Row justify="center">
        <Col xs={24} sm={24} md={22} lg={18} xl={16}>
          
          <div className="panel-body">

            <div className="actionbar">
              <Row justify="space-between" align="middle">
                <Col>
                  <div className="actionbar-links">
                    <Link to="/list">
                      <Button className="margin-r actionbar-link hide-on-mob" type="text" title="See content list" icon={<FolderAddOutlined />}>List</Button>
                      <Button className="margin-r actionbar-link show-on-mob" type="text" title="See content list" size="large" icon={<FolderAddOutlined />} />
                    </Link>
                    <Link to={"/statistics"}>
                      <Button className="margin-r actionbar-link hide-on-mob actionbar-link-active" type="text" title="Content statistics" icon={<BarChartOutlined />}>Statistics</Button>

                      <Button className="margin-r actionbar-link actionbar-link-active show-on-mob" type="text" title="Content statistics" size="large" icon={<BarChartOutlined />} />
                    </Link>
                  </div>
                </Col>
                <Col>
                  <div className="actionbar-controls">
                  </div>
                </Col>
              </Row>
            </div>

            <div className="cm-h5p-container">
              <div className="cm-h5p-inner-container">
                { isLoading ? renderLoading() : renderContent() }
                { renderDetails() }
              </div>
            </div>

          </div>
          
        </Col>
      </Row>
    </>
  )
}

export default PageStatistics;