import ReactDOM from 'react-dom';
import { IconContext } from "react-icons";
//Redux Toolkit
import { Provider } from 'react-redux';
import { store } from './store'

import App from './App';
import * as serviceWorker from './serviceWorker';

import { setDev } from './reducers/userReducer';

store.dispatch( setDev(false) );

ReactDOM.render(
    <Provider store={store}>
        <IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}>
            <App />
        </IconContext.Provider>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
