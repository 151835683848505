import { useSelector } from 'react-redux';

import { Redirect, Route } from 'react-router-dom';

export const PrivateRoute = ({ children, ...rest }) => {

  const { isLoggedIn } = useSelector(state => state.user);
  
  //render
  return (
    <Route {...rest} render={() => {
      return isLoggedIn ? children : <Redirect to='/' />
    }} />
  )

}