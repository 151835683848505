export class StatisticsService {

    static BACKEND = 'https://h5p.westernsydney.edu.au';

    static async getEvents(contentId) {
        console.log(`[StatisticsService] getEvents()`);
        //fetch
        const res = await fetch(`${this.BACKEND}/h5p/statistics/${contentId}`, {
            method: 'GET',
            credentials: 'include'
        });
        //parse
        return await res.json();
    }

    static async addEvent(contentId, type, user=null, data={}) {
        console.log(`[StatisticsService] addEvent(${contentId})`);
        //fetch
        return fetch(`${this.BACKEND}/h5p/statistics/${contentId}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                type,
                user,
                data
            }),
            credentials: 'include'
        });
    }
}



    