//Antd
import { Spin, } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export const LoaderHidden = () => {

    //render
    return (
        <div className="loader-hidden">
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={true}>
                <div style={{height: 40}}></div>
            </Spin>
        </div>
    )   
}