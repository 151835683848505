//antd
import { Col, Row } from 'antd';

export const Error = (props) => {

  const { code, message, details } = props;

  return (
    <Row justify="center">
      <Col xs={24} sm={24} md={22} lg={18} xl={16}>
        <div className="panel-body">
          <div className="error-container">
            <Row justify="center">
              <Col>
                <div className="container-error-message">
                  <div className="error-message">{message || 'An unknown error has occurred.'}</div>
                  { details && <div className="error-details">{details}</div> }
                  { code && <div className="error-code">{code}</div> }
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </Row>
  )
}