//React
import { useState } from 'react';
//Antd
import { Alert, Button, Col, Input, Row, Space, Spin, Tag } from 'antd';
import { LoadingOutlined, ShareAltOutlined  } from '@ant-design/icons';

export const Deleter = (props) => {
    //props
    const { onCancel, onDelete, currentItems } = props;
    //local state
    //const [ isLoading, setLoading ] = useState(false);
    const [ isConfirmed, setIsConfirmed ] = useState(false);

    const onChangeConfirmation = (e) => {
        if (e.target.value.trim().toLowerCase() === 'permanently delete') {
            setIsConfirmed(true);
        } else {
            setIsConfirmed(false);
        }
    }

    const onClickDelete = (e) => {
        //check if confirmed before dispatching delete
        if (isConfirmed && (typeof onDelete === 'function')) onDelete();
    }

    const onClickCancel = (e) => {
        if (typeof onCancel === 'function') onCancel();
    }
      
    //render
    return (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={false}>
            <Alert
                description={
                    <>
                        <strong>Warning:</strong> <strong>Custom Made H5P</strong> items cannot be restored once deleted. It is highly recommended to download a backup copy prior to all deletions.
                        When deleting items shared by you, or with you, they are removed for <strong>all</strong> creators.
                    </>
                }
                type="warning"
                showIcon
                style={{marginBottom: 16}}
                closable
            />
            <p>Are you sure you want to delete these <strong>{currentItems.length}</strong> content item(s)? Shared are items marked with: <Tag title={`Shared with ? creators`} color="geekblue" icon={<ShareAltOutlined />}>?</Tag></p>
            <ul> 
                { currentItems.map( ({ id, title, contentType, sharing }) => {
                    return (<li key={id} title={id}>{title} ({contentType}) { sharing.length > 0 && <Tag title={`Shared with ${sharing.length} creators`} color="geekblue" icon={<ShareAltOutlined />}>{sharing.length}</Tag> }</li>)
                } ) } 
            </ul>
            
            <Row gutter={[10, 20]}>
                <Col span={24}>
                    <p>Please type <em>'permanently delete'</em> in the field below to unlock the delete button</p>
                    <Input type="text" placeholder="permanently delete" onChange={onChangeConfirmation} />
                </Col>
                <Col span={24} align="right">
                    <Space>
                        <Button onClick={onClickCancel}>Cancel</Button>
                        <Button type="primary" disabled={!isConfirmed} onClick={onClickDelete}>Delete</Button>
                    </Space>
                </Col>
            </Row>
            
        </Spin>
    )
}