import React from 'react'
//React-Router
import { withRouter } from "react-router";
//H5P Components
import { H5PPlayerUI } from '@lumieducation/h5p-react';
//Redux-Toolkit
import { connect } from 'react-redux';
//Antd
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
//Components
import { Error } from './Error';
import { Watermark } from './Watermark';
//Google Analytics
import ReactGA from 'react-ga';
//Utils
import { banner, log } from '../Utils';
import { detectIncognito } from "detectincognitojs";
//Services
import { StatisticsService } from '../services/StatisticsService';

class PageEmbed extends React.Component {
  constructor(props) {
    super();
    log(`[PageNew]`);
    this.h5pEditorRef = React.createRef();
    this.state = {
      isLoading: true,
      error: null
    }
  }
  //mount
  async componentDidMount() {
    //show banner in console
    banner();
    //apply background
    document.body.classList.remove(...document.body.classList);
    document.body.classList.add("page-embed", "bg-none");
    //analytics
    ReactGA.pageview(window.location.pathname + window.location.search);
    //get content id
    const { id } = this.props.match.params;
    //throw error if missing
    if (!id) {
      this.setState(
        { 
          error: {
            message: 'Sorry, no content id was supplied.',
            details: 'Please check the URL of this content item is correct.'
          },
          isLoading: false
        }
      )
      return;
    }
    //check if incognito
    const { isPrivate } = await detectIncognito();
    //throw error if private browsing
    if (isPrivate) {
      this.setState(
        { 
          error: {
            message: 'Sorry, this content item is not viewable in incognito or private browsing mode.',
            details: 'Please reload this page in a regular browser window.'
          },
          isLoading: false
        }
      )
      return;
    }

    const { contentService } = this.props;
    //attempt to load
    try {
      //get player data
      const playerData = await contentService.getEmbed(id);
      //set state
      this.setState({ playerData, isLoading: false });
    } catch (err) {
      this.setState({
        error: {
          message: err.message
        },
        isLoading: false
      });
    }
  }

  //render
  render() {
    //props/params
    const { id } = this.props.match.params;
    //local state
    const { isLoading, playerData, error } = this.state;

    //display error message
    if (error) return <Error {...error} />

    //render item (with loading wrapper)
    return (
      <div className="cm-h5p-container">
        
        { /* If loading, render spinner */ }
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={isLoading}>
                
        { /* If not loading, render player */ }
        { (!isLoading) && (
          <div className="h5p-player-container">
            <Watermark />
            <H5PPlayerUI
              ref={this.h5pEditorRef}
              //get withRouter match params id and use as content type id for URL path
              contentId={id}
              loadContentCallback={ (contentId) => playerData }
              onInitialized={this.onPlayerInitialized}
              onxAPIStatement={(statement, context, event) => {
                //get user
                const { user } = this.props;
                //send statistics event
                StatisticsService.addEvent(id, 'xAPI', user, {
                  statement,
                  context,
                  event
                });
              }}
            />
          </div>
        )}
        </Spin>
      </div>
    )
  }

  //handlers
  onPlayerInitialized = () => {
    log(`[PageEmbed] onPlayerInitialised`);
  };
}

const mapStateToProps = (state) => ({
  token: state.user.token,
  user: state.user
});

//Wrap page in withRouter so it picks up content type id for props
export default withRouter( connect(mapStateToProps)(PageEmbed) );