//React
import { useEffect } from 'react';
//Google Analytics
import ReactGA from 'react-ga';
//Antd
import { Button, Col, Row } from 'antd';
import { AiOutlineLeft } from "react-icons/ai";
//Router
import { useHistory } from 'react-router-dom';

const PagePrivacyPolicy = () => {

  //hooks
  const history = useHistory();
  
  //mount
  useEffect(() => {
    document.body.classList.remove(...document.body.classList);
    document.body.classList.add("page-privacy-policy", "bg-image");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  //handlers
  const onClickBack = () => {
    history.goBack();
  }

  //render
  return (
    <div className="body-general">

      <Row justify="center">
        <Col xs={22} sm={18} md={18} lg={14} xl={12}>
          
          <div className="actionbar-transparent">
            <Row justify="space-between" align="middle">
              <Col>
                <div className="actionbar-links">
                  <img src="/images/logo-wsu-h5p-strap.svg" height={35} alt={process.env.REACT_APP_PRODUCT_NAME}/>
                </div>
              </Col>
              <Col>
                <div className="actionbar-controls">
                  <Button type="primary" onClick={onClickBack}><AiOutlineLeft /> Back</Button>
                </div>
              </Col>
            </Row>
          </div>

          <div className="panel-body-container">
            <h1>Privacy Policy</h1>

            <p>At {process.env.REACT_APP_PRODUCT_NAME}, accessible from h5p.westernsydney.edu.au, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by {process.env.REACT_APP_PRODUCT_NAME} and how we use it.</p>

            <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>

            <p>This Privacy Policy applies only to our online activities and is valid for visitors to our application with regards to the information that they shared and/or collect in {process.env.REACT_APP_PRODUCT_NAME}. This policy is not applicable to any information collected offline or via channels other than this website.</p>

            <h2>Consent</h2>

            <p>By using our application, you hereby consent to our Privacy Policy and agree to its terms.</p>

            <h2>Information we collect</h2>

            <p>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</p>
            <p>If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</p>
            <p>When you register for an Account, we may ask the reason for access and your contact information, including items such as name, company name, address, email address, and telephone number.</p>

            <h2>How we use your information</h2>

            <p>We use the information we collect in various ways, including to:</p>

            <ul>
              <li>Provide, operate, and maintain our application</li>
              <li>Improve, personalize, and expand our application</li>
              <li>Understand and analyse how you use our application</li>
              <li>Develop new products, services, features, and functionality</li>
              <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the application, and for marketing and promotional purposes</li>
              <li>Send you emails</li>
              <li>Find and prevent fraud</li>
            </ul>

            <h2>Log Files</h2>

            <p>{process.env.REACT_APP_PRODUCT_NAME} follows a standard procedure of using log files. These files log visitors when they visit the application. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analysing trends, administering the site, tracking users' movement on the application, and gathering demographic information.</p>

            <h2>Cookies and Web Beacons</h2>

            <p>Like any other application, {process.env.REACT_APP_PRODUCT_NAME} uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the application that the visitor accessed or visited. The information is used to optimise the users' experience by customizing our online content based on visitors' browser type and/or other information.</p>

            <h2>Advertising Partners Privacy Policies</h2>

            <p>You may consult this list to find the Privacy Policy for each of the advertising partners of {process.env.REACT_APP_PRODUCT_NAME}.</p>

            <p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on {process.env.REACT_APP_PRODUCT_NAME}, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on tools that you visit.</p>

            <p>Note that {process.env.REACT_APP_PRODUCT_NAME} has no access to or control over these cookies that are used by third-party advertisers.</p>

            <h2>Third Party Privacy Policies</h2>

            <p>{process.env.REACT_APP_PRODUCT_NAME}'s Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. </p>

            <p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.</p>

            <h2>Children's Information</h2>

            <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>

            <p>{process.env.REACT_APP_PRODUCT_NAME} does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our application, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>
            
            <h2>Attribution</h2>
            <p>Images provided by Unsplash: <a href="https://unsplash.com/@jplenio" target="_blank" rel="noreferrer noopener">Johannes Plenio</a>, <a href="https://unsplash.com/@sickle" target="_blank" rel="noreferrer noopener">Sergey Pesterev</a>, <a href="https://unsplash.com/@jeanbeller" target="_blank" rel="noreferrer noopener">Jéan Béller</a>, <a href="https://unsplash.com/@sharissajohnson" target="_blank" rel="noreferrer noopener">Sharissa Johnson</a>, <a href="https://unsplash.com/es/@d1280" target="_blank" rel="noreferrer noopener">Dharmendra Sahu</a>, <a href="https://unsplash.com/@martinsanchez" target="_blank" rel="noreferrer noopener">Martin Sanchez</a></p>
          </div>

        </Col>
      </Row>

    </div>
  )
}

export default PagePrivacyPolicy;