export class LTIService {

    static async getLaunchInfo(token) {
        const launchInfo = await fetch('/lti/info', { 
            method: 'GET', 
            credentials: 'include', 
            headers: { 
                Authorization: 'Bearer ' + token 
            }
        });
        return await launchInfo.json();
    }

    static async deepLink(token, resource) {
        console.log('LTIService.deepLink()');
        //console.log(token);
        //console.log(resource);
        const response = await fetch('/lti/deeplink', { 
            method: 'POST',
            credentials: 'include', 
            body: JSON.stringify(resource),
            headers: { 
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json' //so that body-parser automatically parses it
            }
        });
        return await response.text();
    }
}



    