import React from 'react'
//Router
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
//Google Analytics
import ReactGA from 'react-ga';
//H5P Components
import { H5PEditorUI } from '@lumieducation/h5p-react';
//Antd
import { Button, Col, message, Row } from 'antd';
import { FolderAddOutlined, PlusCircleFilled } from '@ant-design/icons';
//React-Icons
import { AiOutlineCloseCircle, AiFillSave } from 'react-icons/ai';
//Redux-Toolkit
import { connect } from 'react-redux';
//Components
import { Tags } from './Tags';
import { LoaderHidden } from './LoaderHidden';
import { LTIService } from '../services/LTIService';
//Utils
import { log } from '../Utils';

class PageNew extends React.Component {
  
  constructor(props) {
    super();
    log(`[PageNew]`);
    //ref
    this.h5pEditorRef = React.createRef();
    //state
    this.state = {
      tags: '',
      isEditorLoaded: false,
      isLoading: false,
    }
  }

  //mount
  async componentDidMount() {
    //destructure props
    const { ltiToken, isLTI } = this.props;
    //set classes
    document.body.classList.remove(...document.body.classList);
    document.body.classList.add("page-new", "bg-image");
    //check if LTI
    if (isLTI) {
      //get launch info
      const launchInfo = await LTIService.getLaunchInfo(ltiToken);
      //add site title to tags
      this.setState({ tags: launchInfo?.context?.title });
    }
    //register page view
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  //render
  render() {
    //destructure props
    const { contentService, token } = this.props;
    const { isEditorLoaded, tags } = this.state;
    //return
    return (
      <>    
        <Row justify="center">
          <Col xs={24} sm={24} md={22} lg={18} xl={16}>
              <div className="panel-body">

                <div className="actionbar">
                  <Row justify="space-between" align="middle">
                    <Col>
                      <div className="actionbar-links">
                        <Link to="/list">
                          <Button className="margin-r actionbar-link hide-on-mob" type="text" title="See content list" icon={<FolderAddOutlined />}>List</Button>
                          <Button className="margin-r actionbar-link show-on-mob" type="text" title="See content list" size="large" icon={<FolderAddOutlined />} />
                        </Link>
                        <Button className="margin-r actionbar-link hide-on-mob actionbar-link-active" type="text" title="Create new item" icon={<PlusCircleFilled />}>New</Button>
                        <Button className="margin-r actionbar-link show-on-mob actionbar-link-active" type="text" title="Create new item" size="large" icon={<PlusCircleFilled />}>New</Button>
                      </div>
                    </Col>
                    <Col>
                      <div className="actionbar-controls">
                        <Link to="/list">
                          <Button className="margin-l hide-on-mob" title="Return to content list">Cancel</Button>
                          <Button className="margin-l show-on-mob" title="Return to content list" size="large" icon={<AiOutlineCloseCircle />} />
                        </Link>
                        <Button disabled={!isEditorLoaded} className="margin-l hide-on-mob" type="primary" onClick={this.onClickSave} title="Save content">Save</Button>
                        <Button disabled={!isEditorLoaded} className="margin-l show-on-mob" type="primary" onClick={this.onClickSave} title="Save content" size="large" icon={<AiFillSave />}></Button>
                      </div>
                    </Col>
                  </Row>
                </div>
                

                <div className="cm-h5p-container">
                  <div className="cm-h5p-inner-container">
                    
                    <LoaderHidden />

                    <div className="cm-h5p-content-container">

                      { isEditorLoaded && <Tags value={tags} onChange={this.onChangeTags} /> }

                      <H5PEditorUI
                        ref={this.h5pEditorRef}
                        contentId="new"
                        loadContentCallback={ async (contentId) => {
                          log('[H5PEditorUI] loadContentCallback');
                          //load item
                          const res = await contentService.getEdit(contentId, token);
                          //write tags into state
                          if (res.tags) this.setState({ tags : res.tags });
                          //return loaded item
                          return res;
                        }}
                        saveContentCallback={ async (contentId, requestBody) => {
                          log('[H5PEditorUI] saveContentCallback');
                          //attach tags to request body
                          requestBody.tags = tags;
                          //console.log(requestBody);
                          //save item
                          return await contentService.save(contentId, requestBody, token);
                        }}
                        onSaved={this.onSaved}
                        onLoaded={this.onEditorLoaded}
                        onSaveError={this.onSaveError} //this also handles validation
                      />
                    </div>

                  </div>
                </div>
                
              </div>
          </Col>
        </Row>
      </>
    )
  }

  //handlers
  onChangeTags = (tags) => this.setState({ tags });
  onClickSave = async () => {
    log(`[PageNew] onClickSave`);
    try {
      await this.h5pEditorRef.current?.save();
    } catch (err) {
      //ignore this error, as we're listening for save-error (and validation-error)
    }
  }
  onSaveError = (msg) => {
    log(`[PageNew] onSaveError`);
    log(msg);
    message.error(msg);
  };
  onSaved = async (id) => {
    log(`[PageNew] onSaved`);
    message.success({ content: `Successfully saved item` });
    //go to preview
    setTimeout(() => {
      this.props.history.push(`/preview/${id}`);
    }, 500);
  };
  onEditorLoaded = () => { //triggered after content type picked and editor is actually loaded
    log(`[PageNew] onEditorLoaded`);
    this.setState({ isEditorLoaded: true });
  };
}

//global state to props
const mapStateToProps = (state) => ({
  token: state.user.token,
  isLTI: state.user.isLTI,
  ltiToken: state.user.ltiToken,
  background: state.user.background
});

export default withRouter( connect(mapStateToProps)(PageNew) );