//React
import { useEffect, useState } from 'react';
//Antd
import { Button, Col, Row, Spin, Space, Transfer, message } from 'antd';
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
//Services
import { SharingService } from '../services/SharingService';

export const SharePicker = (props) => {

    //destructure props
    const { currentItemId, onClose, token } = props;

    //local states
    const [ isLoading, setLoading ] = useState(true);
    const [ users, setUsers ] = useState([]);
    const [ selectedUserIds, setSelectedUserIds ] = useState([]);

    //mount
    useEffect(() => {
        const getUserListData = async () => {
            //show loading
            setLoading(true);
            //get all 'sharable' users (i.e. creators, admins)
            let users = await SharingService.getSharableUsers(token);
            //add key for each entry
            users = users.map( user => ({ ...user, key: user._id }));
            //sort by alphabetical order
            users.sort((a,b) => a.name.localeCompare(b.name));
            //set users
            setUsers(users);
            //get selected users (i.e. creators, admins)
            const selectedUserIds = await SharingService.getSharedUsers(currentItemId, token);
            //console.log(selectedUserIds);
            setSelectedUserIds(selectedUserIds);
            //hide loading
            setLoading(false);
        }
        getUserListData();
    }, [currentItemId, token]);

    //handlers
    const onClickSave = async (e) => {
        //show loading
        setLoading(true);
        //save selected users
        await SharingService.setSharedUsers(currentItemId, selectedUserIds, token);
        //show confirmation prompt
        message.success('Updated shared users');
        //hide loading
        setLoading(false);
        //dispatch onClose if defined
        if (typeof onClose === 'function') onClose();
    }

    const onClickRefresh = async (e) => {
        //show loading
        setLoading(true);
        //get all users
        let users = await SharingService.getSharableUsers(token);
        //add key for each entry
        users = users.map( user => ({ ...user, key: user._id }));
        //sort by alphabetical order
        users.sort((a,b) => a.name.localeCompare(b.name));
        //set users
        setUsers(users);
        //hide loading
        setLoading(false);
        //message
        message.success('Refreshed user list');
    }

    //filtering
    const filterOption = (inputValue, option) => {
        //get search value, trim and lowercase
        const searchValue = inputValue.trim().toLowerCase();
        return ( option.name.toLowerCase().includes(searchValue) || option.email.toLowerCase().includes(searchValue) )
    }

    //handers
    const onClickCancel = () => {
        if (typeof onClose === 'function') onClose();
    }

    //render
    return (
        <>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={isLoading}>
                <Transfer
                    dataSource={users}
                    showSearch
                    filterOption={filterOption}
                    targetKeys={selectedUserIds}
                    onChange={keys => setSelectedUserIds(keys)}
                    className="cm-tranfer" 
                    listStyle={{margin: "0 auto", backgroundColor: "white", width: '40%', height: 360}}
                    render={item => (
                        <>
                            <span className="name">{item.name} </span>
                            <span className="email" style={{fontSize: 12}}>({item.email})</span>
                        </>
                    )}
                    showSelectAll={false}
                    
                    selectAllLabels={[
                        ({selectedCount, totalCount}) => <span>Available users: {totalCount}</span>,
                        ({selectedCount, totalCount}) => <span>Shared with users: {totalCount}</span>,
                    ]}
                />
            </Spin>
            <div className="border-top margin-t padding-t">
                <Row justify="end">
                    <Col>
                        <Space>
                            <Button onClick={onClickRefresh} icon={<ReloadOutlined />} title="Refresh user list" />
                            <Button onClick={onClickCancel}>Cancel</Button>
                            <Button onClick={onClickSave} type="primary">Save</Button>
                        </Space>
                    </Col>
                </Row>
            </div>
        </>
    )
    
}