import type { IEditorModel, IPlayerModel, IContentMetadata } from '@lumieducation/h5p-server';

export interface IContentListEntry {
    contentId: string;
    mainLibrary: string;
    title: string;
    originalNewKey?: string;
}

export interface IContentService {
    copyFiles(existingId: string, newId: string, token:string): Promise<any>;
    delete(contentIds: string[], token:string): Promise<void>;
    getEdit(contentId: string, token:string): Promise<IEditorModel>;
    getPlay(contentId: string, token:string): Promise<IPlayerModel>;
    getEmbed(contentId: string): Promise<IPlayerModel>;
    list(token:string): Promise<IContentListEntry[]>;
    save(contentId: string, requestBody: { library: string; params: any }, token: string): Promise<{ contentId: string; metadata: IContentMetadata }>;
    download(contentId: string, token:string): Promise<string>;
}

export class ContentService {
 
    constructor(protected baseUrl: string = '') {}

    private csrfToken: string | undefined = undefined;

    delete = async (contentIds: String[], token: string): Promise<void> => {
        //ensure content ids is array
        contentIds = Array.isArray(contentIds) ? contentIds : [contentIds];
        //log
        console.log(`[ContentService] delete() with ${contentIds.join()}`)
        //prepare headers
        var headers = new Headers();
        //headers.append("Authorization", token);
        //headers.append("CSRF-Token", this.csrfToken ?? '');
        //prepare url payload
        const payload = JSON.stringify(contentIds);
        //fetch
        const res = await fetch(`${this.baseUrl}/${payload}`, {
            headers: headers,
            credentials: 'include',
            method: 'DELETE'
        });
        //on error
        if (!res.ok) throw new Error(`Error while deleting content: ${res.status} ${res.statusText} ${await res.text()}`);
        //on success, respond
        return res.json();
    };

    copyFiles = async (contentId: string, newId: string, token: string): Promise<any> => {
        console.log(`[ContentService]: copy() with ${contentId}...`);
        //prepare headers
        var headers = new Headers();
        //headers.append("Authorization", token);
        //headers.append("CSRF-Token", this.csrfToken ?? '');
        //fetch
        const res = await fetch(`${this.baseUrl}/copy/${contentId}/${newId}`, {
            headers: headers,
            credentials: 'include',
            method: 'POST'
        });
        //on error
        if (!res.ok) throw new Error(`Error while copying files: ${res.status} ${res.statusText} ${await res.text()}`);
        //on success, respond
        return res.json();
    };

    getEdit = async (contentId: string, token: string): Promise<IEditorModel> => {
        console.log(`[ContentService] getEdit() with ${contentId}...`);
        //prepare headers
        var headers = new Headers();
        //headers.append("Authorization", token);
        //fetch
        const res = await fetch(`${this.baseUrl}/edit/${contentId}`, {
            headers: headers,
            credentials: 'include',
            method: 'GET'
        });
        //on error
        if (!res || !res.ok) throw new Error(`${res.status} ${res.statusText}`);
        //on success, respond
        return res.json();
    };

    getPlay = async (contentId: string, token: string): Promise<IPlayerModel> => {
        console.log(`[ContentService] getPlay() with ${contentId}...`);
        //prepare headers
        const headers = new Headers();
        //headers.append("Authorization", token);
        //fetch
        const res = await fetch(`${this.baseUrl}/play/${contentId}`, {
            headers: headers,
            credentials: 'include',
            method: 'GET'
        });
        //on error
        if (!res || !res.ok) throw new Error(`${res.status} ${res.statusText}`);

        const json = await res.json();
        console.log(json);
        //on success, respond
        return json;
    };

    getEmbed = async (contentId: string) => {
        console.log(`[ContentService] getEmbed() with ${contentId}...`);
        //fetch
        const res = await fetch(`${this.baseUrl}/embed/${contentId}`, {
            method: 'GET',
            credentials: 'include',
        });
        console.log(res);
        //on error
        if (!res || !res.ok) throw new Error(`${res.status} ${res.statusText}`);
        //on success, respond
        return res.json();
    };

    list = async (token: string): Promise<IContentListEntry[]> => {
        console.log(`[ContentService] list()`);
        //prepare headers
        const headers = new Headers();
        //headers.append("Authorization", token);
        //fetch
        const result = await fetch(this.baseUrl, {
            headers: headers,
            credentials: 'include',
            method: 'GET'
        })
        console.log(result);
        //on success
        if (result.ok) return result.json();
        //on error
        throw new Error(`Request to REST endpoint returned ${result.status} ${result.statusText}: ${await result.text()}`);
    };

    save = async (contentId: string, requestBody: { library: string; params: any, tags: string }, token: string): Promise<{ contentId: string; metadata: IContentMetadata }> => {
        console.log( (contentId) ? `[ContentService]: save() with existing content (${contentId})` : `[ContentService]: save() with new content` );
        console.log(requestBody);
        //prepare headers
        var headers = new Headers();
        //headers.append("Authorization", token);
        //headers.append("CSRF-Token", this.csrfToken ?? '');
        headers.append('Content-Type', 'application/json');
        //prepare body
        const body = JSON.stringify(requestBody);
        //fetch
        const res = contentId
            //update existing item
            ? await fetch(`${this.baseUrl}/${contentId}`, {
                headers,
                credentials: 'include',
                method: 'PATCH',
                body
            })
            //save new item
            : await fetch(this.baseUrl, {
                headers,
                credentials: 'include',
                method: 'POST',
                body
            });
        //console.log(res);
        //on error
        if (!res || !res.ok) throw new Error(`${res.status} ${res.statusText} - ${await res.text()}`);
        //on success, respond
        return res.json();
    };

    download = async (id:string, token:string): Promise<string> => {
        console.log(`[ContentService] download()`);
        //prepare headers
        var headers = new Headers();
        //headers.append("Authorization", token); //only authorised users can download
        //fetch
        const res = await fetch(`${this.baseUrl}/download/${id}`, {
            headers: headers,
            credentials: 'include',
            method: 'GET'
        });
        //parse blob from response
        const blob = await res.blob();
        //console.log(blob);
        //create another blob with correct mime
        //const newBlob = new Blob([blob], { type: 'application/zip' })
        //return url as string
        return URL.createObjectURL(blob);
    }

    setCsrfToken = (csrfToken): void => {
        this.csrfToken = csrfToken;
    };
    getCsrfToken = (): string | undefined => {
        return this.csrfToken;
    };
    
}
