import { useRef, useState, useEffect } from 'react';
//Antd
import { Button, Col, Row, message, Input, Space, Checkbox, Tag } from 'antd';
//Utils
import { getSafeLibraryName } from '../Utils';

export const Embedder = (props) => {
  //props
  const { onClose, currentItem } = props;

  const refTextarea = useRef();

  //override height
  let defaultHeight = 540;
  switch (currentItem.mainLibrary) {
    case 'H5P.Banner':
      defaultHeight = 240;
      break;
    case 'H5P.AcknowledgementOfCountry':
      defaultHeight = 240;
      break;
    default: 
      defaultHeight = 540;
      break;
  }

  const [embedCode, setEmbedCode] = useState('');
  const [height, setHeight] = useState(defaultHeight);
  const [width, setWidth] = useState(960);
  const [isFullWidth, setIsFullWidth] = useState(true);
  const [includeResizerScript, setIncludeResizerScript] = useState(true);
  const [includeBBTemplateVariables, setIncludeBBTemplateVariables] = useState(true);
  
  //auto-update the embed code value in state if width, height or value changes otherwise
  useEffect(() => {
    const generateEmbedCode = () => {
      //generate a class name(s)
      const className = [
        'custom-made-h5p', 
        getSafeLibraryName(currentItem.mainLibrary)
      ].join(' ');
      const baseURL = `${window.location.protocol}//${window.location.host}`;
      const embedSrc = `${baseURL}/embed/${currentItem.id}`;
      const resizerSrc = `${baseURL}/js/custom-made-h5p-resizer.js`;
      //prepare code
      let code = `<iframe class="${className}" src="${embedSrc}" width="${width}" height="${height}" frameborder="0" allowfullscreen="allowfullscreen" allow="autoplay *; camera *; encrypted-media *; fullscreen *; geolocation *; microphone *;  midi *" data-version="1.0"`;
      //add optional properties
      code += (isFullWidth) ? ` style="width: 100%"` : '';
      code += (includeBBTemplateVariables) ? ` data-user-id="@X@user.id@X@" data-user-name="@X@user.full_name@X@" data-course-role="@X@course.role@X@" data-course-id="@X@course.id@X@"` : '';
      //close iframe
      code += '></iframe>'
      //add resizer script
      code += (includeResizerScript) ? `<script class="custom-made-h5p-resizer" src="${resizerSrc}" charset="UTF-8" data-version="1.0"></script>` : '';
      //remove escaped characters
      return code.replace(/\\/g,'');
    }
    setEmbedCode(generateEmbedCode());
  }, [currentItem, width, height, isFullWidth, includeResizerScript, includeBBTemplateVariables])

  //copy to clipboard
  const copyToClipboard = () => {
    const el = refTextarea.current;
    el.select()
    document.execCommand("copy");
    //confirmation success
    message.success('Copied embed code');
  }
  //change width based on size input
  const changeWidth = (e) => setWidth(e.target.value)
  
  //change height based on size input
  const changeHeight = (e) => setHeight(e.target.value)
  
  return (
    <div>
      <Row gutter={[20, 10]}>
        <Col span={24}>
          <strong>Embed code</strong>
        </Col>
        <Col span={24}>
          <textarea
            className="margin-b"
            readOnly={false}
            ref={refTextarea}
            value={embedCode}
            style={{width:'100%', minHeight: 132, fontFamily: 'monospace', padding: 4, fontSize: 13, lineHeight: 1.2}}
            onChange={(e) => { setEmbedCode(e.target.value) }}
          /> 
        </Col>
      </Row>
      <Row gutter={[20, 10]}>
        <Col xs={24} sm={12}> 
          <Row gutter={[20, 10]}>
            <Col span={24}>
              <strong>Embed options</strong>
            </Col> 
            <Col span={24}>
              <Row gutter={[20, 10]} align="middle">
                <Col span={12}>
                  Width
                  <Input type="number" value={width} onChange={changeWidth} min={360} placeholder={960} suffix="px" disabled={isFullWidth} style={{width: '100%'}} />
                </Col>
                <Col span={12}>
                  Height
                  <Input type="number" value={height} onChange={changeHeight} min={100} placeholder={540} suffix="px" style={{width: '100%'}} />
                </Col>
                <Col xs={24}>
                  <Checkbox onChange={e => setIsFullWidth(e.target.checked)} checked={isFullWidth}>Fill available width</Checkbox>
                </Col>
              </Row>
            </Col> 
          </Row>
        </Col>

        <Col xs={24} sm={12}>
          <Row gutter={[20, 10]}>
            <Col span={24}>
              <strong>Advanced options</strong>
            </Col>
            <Col xs={24}>
              <Checkbox onChange={e => setIncludeResizerScript(e.target.checked)} checked={includeResizerScript}>Include resizer script <Tag color="geekblue">Beta</Tag></Checkbox>
            </Col>
            <Col xs={24}>
              <Checkbox onChange={e => setIncludeBBTemplateVariables(e.target.checked)} checked={includeBBTemplateVariables}>Include template variables (Blackboard) <Tag color="geekblue">Beta</Tag></Checkbox>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="border-top margin-t padding-t">
        <Row justify="end">
          <Col>
            <Space>
              <Button onClick={onClose}>Cancel</Button>
              <Button onClick={copyToClipboard} type="primary" title="Copy to Clipboard">Copy embed code</Button>
            </Space>
          </Col>
        </Row>
      </div>
    </div>
  )
}

