import { Component } from 'react';
//React-Router
import { Link, withRouter } from 'react-router-dom';
//Actions
import { setWelcomed } from '../reducers/userReducer';
//Google Analytics
import ReactGA from 'react-ga';
//Antd
import { Alert, Button, Col, Dropdown, Empty, Menu, message, Modal, Row, Table, Tooltip } from 'antd';
//Icons
import { BarChartOutlined, CopyOutlined, DeleteFilled, DownloadOutlined, EditOutlined, EllipsisOutlined, LoadingOutlined, PlusCircleFilled, ReloadOutlined, StopOutlined, ShareAltOutlined } from '@ant-design/icons';
//React-Icons
import { IoClose, IoCodeSlash } from "react-icons/io5";
import { BsCalendar4Week, BsFillArrowUpLeftCircleFill,BsShareFill } from "react-icons/bs";
import { AiOutlineTag } from 'react-icons/ai';
//Component imports 
import { Downloader } from './Downloader';
import { SharePicker } from './SharePicker';
import { Embedder } from './Embedder';
import { Deleter } from './Deleter';
//Utils
import { escapeHtml, formatModifiedDate, log, truncate } from '../Utils';

import $ from 'jquery'

//Services
import { LTIService } from '../services/LTIService';

//Redux-Toolkit
import { connect } from 'react-redux';

class PageList extends Component {
    constructor(props) {
        super();
        log(`[PageList]`);
        this.state = { 
            isLTI: false,
            ltiKey: null,
            tableData: [], //all items
            currentItems: [], //the actual current items selected [{},{},{},...]
            currentItemIds: [], //the ids of all current items selected ['123','456,'789'...] (the table needs this)
            currentModal: null,
            isLoading: true
        };
        this.contentService = props.contentService;
    }

    //mount
    async componentDidMount() {
        document.body.classList.remove(...document.body.classList);
        document.body.classList.add("page-list", "bg-image");
        ReactGA.pageview(window.location.pathname + window.location.search);
        //update list
        await this.updateList();
        
    };

    //umnmount
    async componentWillUnmount() {
        this.setState({currentModal: null})
    };

    //Update items list
    async updateList() {
        log(`[PageList] updateList`);
        //set as loading
        this.setState({ isLoading: true });
        //get raw item list from content service
        const rawData = await this.contentService.list(null);
        //format raw data in format table wants (also, include hidden fields)
        const tableData = rawData.map(item => ({
            id: item.contentId,
            key: item.contentId,
            escapedTitle: item.title,
            title: escapeHtml(item.title),
            contentType: item.contentType,
            user: item.user,
            sharing: item.sharing,
            lastModified: item.modifiedAt,
            //owner: item.user,
            tags: item.tags,
            mainLibrary: item.mainLibrary
        }));
        //push updated list into state, stop loading
        this.setState({ tableData, isLoading: false }); 
    }

    //Renderer: Welcome
    renderWelcome() {
        const { dispatch, isWelcomed, user } = this.props;
        if (!isWelcomed) {
            return (
                <Modal 
                    title="Welcome to Custom Made H5P" 
                    visible={true}
                    footer={null}
                    centered={true}
                    closable={false}
                >
                    <p>Welcome {(user)&& (<strong>{user?.name}</strong>)}. We hope you're in the right spot.</p>
                    <p>This service contains a subset of H5P content types created by Learning Futures at Western Sydney University.</p>
                    <p>If you're looking to create official <a href="https://h5p.org/content-types-and-applications" target="_blank" rel="noreferrer">H5P.com</a> catalog items, return to vUWS and navigate to <strong>Build Content &gt; Interactive Content - H5P</strong></p>
                    <Button className="cmh5p-primary" onClick={ e => dispatch(setWelcomed(true)) }>I understand</Button>
                </Modal>
            );
        }
    }

    //More Options bulk context menu
    renderBulkContextMenu() {
        const { currentItems } = this.state;
        const menu = (
            <Menu onClick={e => this.setState({ currentModal: 'delete' }) }>
                <Menu.Item disabled={(currentItems.length === 0)} key="delete" icon={<DeleteFilled />} title="Delete content" aria-label="Delete content">Delete</Menu.Item>
            </Menu>
        );
        return menu;
    }
    //More Options context menu
    renderItemContextMenu(item) {
        return (
            <Menu onClick={e => this.onClickItemContentMenu(item, e) }>
                <Menu.Item key="embed" icon={<IoCodeSlash />} title="Embed content" aria-label="Embed content">Embed</Menu.Item>
                <Menu.Item key="share" icon={<ShareAltOutlined />} title="Share content item" aria-label="Share content item">Share</Menu.Item>
                <Menu.Divider />
                <Menu.Item key="copy" icon={<CopyOutlined />} title="Clone content" aria-label="Clone content">Clone</Menu.Item>
                <Menu.Item key="download" icon={<DownloadOutlined />} title="Download content" aria-label="Download content">Download</Menu.Item>
                <Menu.Item key="statistics" icon={<BarChartOutlined />} title="View statistics"><Link to={`/statistics/${item.id}`} aria-label="View statistics"> Statistics</Link></Menu.Item>
                <Menu.Divider />
                <Menu.Item danger key="delete" icon={<DeleteFilled />} title="Delete content" aria-label="Delete content">Delete</Menu.Item>
            </Menu>
        )
    }

    renderModal() {
        switch (this.state.currentModal) {
            case 'embed':
                return this.renderEmbedModal();
            case 'share':
                return this.renderShareModal();
            case 'clone':
                return this.renderCloneModal();
            case 'delete':
                return this.renderDeleteModal();
            case 'download':
                return this.renderDownloadModal();
            default: 
                return null; //return null = don't render anything
        }
    }

        //Single item clone modal
        renderCloneModal() {  
            const { currentItems } = this.state;
            return (
                <Modal 
                    title="Clone content" 
                    visible={true}
                    onOk={this.handleClickCloneModalOk}
                    onCancel={() => { this.setState({ currentModal: null }) }}
                    okText="Clone"
                    closeIcon={<IoClose/>}
                    maskClosable={false}
                    width={800}
                >
                    <Alert
                        description={
                            <><strong>Note:</strong> Cloning a content item will not copy any sharing options from the original item.</>
                        }
                        type="warning"
                        showIcon
                        style={{marginBottom: 16}}
                        closable
                    />
                    <p>Are you sure you want to clone <strong>{currentItems.length}</strong> item(s)?</p>
                    <ul> { currentItems.map( ({ id, title, contentType }) => <li key={id} title={id}>{title} ({contentType})</li> ) } </ul> 
                </Modal>
            )
        }

        //Item delete modal (single or multiple)
        renderDeleteModal() {   
            const { currentItems } = this.state;
            return (
                <Modal 
                    title="Delete content item(s)" 
                    visible={true}
                    //onOk={this.handleClickDeleteModalOk}
                    onCancel={ this.closeModal }
                    footer={null}
                    width={800}
                    closeIcon={<IoClose/>}
                    maskClosable={false}
                > 
                    <Deleter currentItems={currentItems} onCancel={ this.closeModal } onDelete={this.handleClickDeleteModalOk} />
                </Modal>
            )
        }

        //Embed item modal
        renderEmbedModal() {   
            const { currentItems } = this.state;
            return (
                <Modal 
                    title="Embed content item" 
                    visible={true}
                    onCancel={this.closeModal}
                    okText="Embed"
                    footer={null}
                    width={800}
                    closeIcon={<IoClose/>}
                    maskClosable={false}
                >
                    <Alert
                        description={
                            <>
                                <strong>Note:</strong> Learner attempt information is currently not recorded for content items deployed via embed code, however this may be enabled for Blackboard in the future.
                            </>
                        }
                        type="warning"
                        showIcon
                        style={{marginBottom: 16}}
                        closable
                    />
                    <Embedder onClose={this.closeModal} currentItem={currentItems[0]} />  
                </Modal>
            )
        }
        closeModal = (e) => {
           this.setState({currentModal: null});
        }

        //Share item modal
        renderShareModal() {   
            const { currentItems } = this.state;
            const { token } = this.props;
            return (
                <Modal 
                    title="Share content item" 
                    visible={true}
                    onCancel={() => { this.setState({ currentModal: null }) }}
                    okText="Share"
                    footer={null}
                    width={800}
                    closeIcon={<IoClose/>}
                    maskClosable={false}
                >
                    <Alert
                        description={
                            <>
                                <strong>Note:</strong> When you share an item with another creator, they will be able to perform the following actions: View, embed, share, edit, clone, download, <strong style={{color: 'red'}}>delete</strong> and access statistics.
                                Only users who have accessed <strong>Custom Made H5P</strong> will be listed below.
                            </>
                        }
                        type="warning"
                        showIcon
                        style={{marginBottom: 16}}
                        closable
                    />
                    <p>Share <strong>{truncate(currentItems[0]?.title)} ({currentItems[0]?.contentType})</strong> with other creators.</p>
                    <p></p>
                    <SharePicker currentItemId={currentItems[0]?.id} onClose={this.onCloseShareModal} token={token} />
                </Modal>
            )
        }
        //handle close share modal
        onCloseShareModal = async (e) => {
            this.setState({currentModal: null})
            await this.updateList();
        }

        //Download item modal
        renderDownloadModal() {   
            const { token } = this.props;
            const { currentItems } = this.state;
            
            return (
                <Modal 
                    title="Download content item" 
                    visible={true}
                    onCancel={() => { this.setState({ currentModal: null }) }} 
                    footer={null}
                    width={800}
                    closeIcon={<IoClose/>}
                    maskClosable={false}
                >
                    <Downloader
                        onCancel={() => { this.setState({ currentModal: null }) }} 
                        currentItem={currentItems[0]}
                        token={token} 
                        contentService={this.contentService} 
                    />
                </Modal>
            )
        }
       

    renderList() {
        //Table row selection via checkbox  
        //const { isLTI } = this.props;
        const { currentItemIds, isLoading } = this.state;

        //Table columns
        const columns = [
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
                sortDirections: ['ascend', 'descend', 'ascend'],
                sorter: {
                    compare: (a, b) => a.title.localeCompare(b.title),
                },
                render: (text, item) => {
                    return (
                        <div>
                            <Tooltip title={item.title}>
                                <Link to={`/preview/${item.id}`} title="Preview content" className="content-type-title" aria-label={"Preview " + item.title}>{truncate(item.title)}</Link>
                                <span className="lighter-text"><small>{item.contentType}</small></span>
                            </Tooltip>
                            <div>
                                { (item.sharing.length > 0) && (
                                    <Tooltip title={`Shared with ${item.sharing.length} user(s)`}>
                                        <Button icon={<BsShareFill />} type="text" size="small" aria-label="Shared with others" />
                                    </Tooltip> 
                                ) }
                            </div>
                        </div>
                    )
                }
            },
            {
                title: 'Tags',
                dataIndex: 'tags',
                key: 'tags',
                sorter: {
                    compare: (a, b) => a.tags.localeCompare(b.tags),
                },
                render: (text, item) => {
                    //Tags don't exist
                    if (!item.tags && item.tags.trim().length === 0) {
                        return (<StopOutlined style={{color: '#BBB'}} />);
                    }
                    //Tags exist
                    return (
                        <div>
                            <Tooltip className="hide-on-mob" title={<div><span>{item.tags}</span></div>}>
                                <div className="hide-on-mob truncate"><small>{truncate(item.tags)}</small></div>
                            </Tooltip>
                            <Tooltip className="show-on-mob" title={<div><span>{item.tags}</span></div>}>
                                <AiOutlineTag style={{ fontSize: '130%'}}/>
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Modified',
                dataIndex: 'lastModified',
                key: 'lastModified',
                defaultSortOrder: 'descend',
                sortDirections: ['descend','ascend','descend'],
                sorter: {
                    compare: (a, b) => a.lastModified.localeCompare(b.lastModified),
                },
                render: (text, item) => {
                    return (
                        <div><small className="hide-on-mob">{formatModifiedDate(item.lastModified)}</small><Tooltip title={formatModifiedDate(item.lastModified)}><BsCalendar4Week className="show-on-mob" style={{ fontSize: '130%'}} /></Tooltip></div>
                    )
                }
            },
            {
                dataIndex: 'embed',
                key: "embed",
                align: "center",
                width: "130px",
                render: (text, item) => (
                    <div className="table-item-actions">

                        { this.renderPrimaryAction(item) } { /* returns Insert or Embed button */ }

                        <Row gutter={[8,0]}>
                            <Col span={12}>
                                <Link to={`/edit/${item.id}`}>
                                    <Button block icon={<EditOutlined/> } title="Edit content" aria-label="Edit content"/>
                                </Link> 
                            </Col>
                            <Col span={12}>
                                <Dropdown
                                    overlay={this.renderItemContextMenu(item)} 
                                    placement="bottomRight" trigger={['click']} 
                                    onClick={ e => { this.setState ({ currentItems: [item], currentItemIds: [item.id] }) }}>
                                    <Button block title="More options" icon={<EllipsisOutlined/>} aria-label="View more options"/>
                                </Dropdown>
                            </Col>
                        </Row>
                        
                    </div>
                )
            }
        ]
        return (
            <Table 
                className="table-content-list"
                columns={columns}
                dataSource={this.state.tableData}
                loading={ isLoading ? { indicator: <LoadingOutlined style={{ fontSize: 24 }} spin /> } : false }
                locale={{ emptyText: 
                    isLoading ? 
                    (
                        <Empty 
                            image={null} 
                            imageStyle={{height: 50, color: "rgba(0,0,0,0.4)", fontSize: "2.5rem"}}
                            description={<span style={{fontSize: "1.2rem", color: "rgba(0,0,0,0.4)"}}>Loading content items...</span>}
                        />    
                    ) : (
                        <Empty 
                            image={<BsFillArrowUpLeftCircleFill />} 
                            imageStyle={{height: 50, color: "rgba(0,0,0,0.4)", fontSize: "2.5rem"}}
                            description={<span style={{fontSize: "1.2rem", color: "rgba(0,0,0,0.4)"}}>Use the <strong>Add <span className="hide-on-mob">Content</span></strong> button to get started</span>}
                        />
                    )
                }}
                //checkbox selection of current row
                rowSelection={{
                    selectedRowKeys: currentItemIds,
                    onChange: this.handleTableRowSelectChange
                }}  
                pagination={{
                    defaultPageSize: 10, 
                    total: this.state.tableData.length,
                    pageSizeOptions:["100", "50", "10"],
                    showSizeChanger: true,
                    showTotal: total => `Total ${total} item${(total > 1) ? 's' : ''}`,
                    hideOnSinglePage: false
                }}
                style={{backgroundColor: "white !important"}}
            />
        )
    }
    //Renderer: Primary action
    renderPrimaryAction(item) {
        return <Button block title="Embed content" className="cmh5p-primary margin-b" onClick={e => this.setState({ currentItems:[item], currentItemIds:[item.id], currentModal: 'embed' })} aria-label="Embed content">Embed</Button>
        /*
        if (isLTI) {
            //in LTI mode
            return <Button block title="Insert content" className="cmh5p-primary margin-b" onClick={e => this.onClickInsert(item, e)} aria-label="Insert content">Insert</Button>
        } else {
            //non-LTI mode
            return <Button block title="Embed content" className="cmh5p-primary margin-b" onClick={e => this.setState({ currentItems:[item], currentItemIds:[item.id], currentModal: 'embed' })} aria-label="Embed content">Embed</Button>
        }
        */
    }

    //Render
    render() {
        const { currentItemIds, isLoading } = this.state;

        return (
            <>
                <Row justify="center">
                    <Col xs={24} sm={24} md={22} lg={18} xl={16}>
                        <div className="panel-body">
                            <div className="actionbar">
                                <Row justify="space-between" align="middle">
                                    <Col>
                                        <div className="actionbar-links">
                                            <Link to="/new">
                                                <Button disabled={isLoading} className="hide-on-mob" type="primary" icon={<PlusCircleFilled />} title="Add content" size="large">Add Content</Button>
                                                <Button disabled={isLoading} className="show-on-mob" type="primary" icon={<PlusCircleFilled />} title="Add content" size="large">Add</Button>
                                            </Link>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="actionbar-controls">
                                            <span>({currentItemIds.length}) selected</span>
                                            <Dropdown disabled={isLoading} overlay={this.renderBulkContextMenu()} placement="bottomRight" trigger={['click']}>
                                                <Button className="margin-l" title="More options" icon={<EllipsisOutlined size="large"/>} />
                                            </Dropdown>
                                            <Button disabled={isLoading} className="margin-l" title="Refresh list" icon={<ReloadOutlined size="large" /> } onClick={ e => { this.updateList() }} aria-label="Refresh list"/>
                                            <Button disabled={isLoading} className="hide-on-mob margin-l" title="View support resources" href="https://lf.westernsydney.edu.au/engage/technology/custom-made-h5p" target="_blank" rel="noreferrer">Help</Button>
                                            <Button disabled={isLoading} className="show-on-mob margin-l" title="View support resources">Help</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            { this.renderList() }
                        </div>
                    </Col>
                </Row> 
                { this.renderModal() }
                { this.renderWelcome() }
            </>
        );
    }

    //Handlers
    onClickInsert = async (item, e) => {
        const { isLTI, ltiToken } = this.props;
        
        //don't do anything if not in LTI
        if (!isLTI) return;

        const itemData = {
            id: item.id,
            title: item.title,
            url: `https://h5p.westernsydney.edu.au/lti/launch?resource=${item.id}` //NOTE: Args must be passed as query
        }

        //call deeplink endpoint
        const form = await LTIService.deepLink(ltiToken, itemData); //deeplink generates a self submitting form, append this to the body
        //TODO: Consider posting the form manually instead of inserting into DOM
        $('body').append(form);
    }

    //More Options item context menu - run Copy or Delete function
    onClickItemContentMenu = (item, e) => {
        switch (e.key) {
            case "embed":
                this.setState({ currentModal: 'embed' });
                break;
            case "share":
                this.setState({ currentModal: 'share' });
                break;
            case "copy":
                this.setState({ currentModal: 'clone' });
                break;
            case "delete":
                this.setState({ currentModal: 'delete' });
                break;
            case "download":
                this.setState({ currentModal: 'download'});
                break;
            default:
                return;
        }
    }

    //Copy item
    handleClickCloneModalOk = async (e) => {
        //get current item ids
        const { currentItemIds } = this.state;
        //count num items
        const numItems = currentItemIds.length;
        //check at least one item exists
        if (numItems <= 0) return;
        //hide modal and reset selections (must do this before navigating away, otherwise modal doesn't destroy properly)
        this.setState({ currentModal: null, currentItemIds: [], currentItems: [] }, () => {
            //go to clone page
            this.props.history.push(`/clone/${currentItemIds}`);
        });
    }

    //Delete Modal - Ok
    handleClickDeleteModalOk = async (e) => {
        const { currentItemIds } = this.state;
        //set as loading
        this.setState({ isLoading: true });
        //delete items
        const res = await this.deleteItems(currentItemIds);
        //stop loading
        this.setState({ isLoading: false});
        //update list
        await this.updateList(); //Note: Also toggles isLoading state
        //close modal, clear selections and stop loading
        this.setState({ currentModal: null, currentItemIds: [], currentItems: [] });
        //show message
        message.success(res?.message)
    }
    
    //Delete item
    async deleteItems(ids) {
        if (!ids) return;
        //if no id sent, abort
        ids = Array.isArray(ids) ? ids : [ids];
        if (ids.length <= 0) return;
        //get token
        const { token } = this.props;
        try {
            //delete from server via api
            return await this.contentService.delete(ids, token);
        } catch (err) {
            log(err);
            return err;
        }
    }

    //Cancel Add new item action
    handleCancelNewItem(content) {
        this.setState({
            contentList: this.state.contentList.filter((c) => c !== content)
        });
    }

    //Save item
    async handleSaveItem(oldData, newData) {
        this.setState({
            contentList: this.state.contentList.map(c => c === oldData ? newData : c)
        });
    }

    //Table - add selected checkboxes to currentItemIds
    handleTableRowSelectChange = currentItemIds => {
        //lookup items
        const currentItems = currentItemIds.map((id) => {
            //find actual item based on it's id, and return that item
            return this.state.tableData.find((item) => (item.id === id));
        });

        this.setState({ currentItems, currentItemIds });
    };

}

const mapStateToProps = (state) => ({
    token: state.user.token,
    //LTI related
    ltiToken: state.user.ltiToken,
    isLTI: state.user.isLTI,
    isWelcomed: state.user.isWelcomed,
    user: state.user.user
});

export default withRouter(connect(mapStateToProps)(PageList) );
