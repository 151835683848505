import React from 'react'
//React-Router
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
//Google Analytics
import ReactGA from 'react-ga';
//H5P Components
import { H5PEditorUI } from '@lumieducation/h5p-react';
//Antd
import { Button, Col, message, Row } from 'antd';
import { EditOutlined, EyeOutlined, FolderAddOutlined } from '@ant-design/icons';
//React-Icons
import { AiFillSave, AiOutlineCloseCircle } from 'react-icons/ai';
//Redux-Toolkit
import { connect } from 'react-redux';
//Components
import { Tags } from './Tags';
import { LoaderHidden } from './LoaderHidden';
//Utils
import { log } from '../Utils';

class PageEdit extends React.Component {

  constructor(props) {
    super();
    //page edit
    log(`[PageEdit]`);
    //ref
    this.h5pEditorRef = React.createRef();
    //state
    this.state = {
      title:'',
      tags: '',
      isEditorLoaded: false,
      isLoading: true
    } 
  }
  
  //mount
  componentDidMount() {
    document.body.classList.remove(...document.body.classList);
    document.body.classList.add("page-edit", "bg-image");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  //renderers
  renderTitle() {
    const { title } = this.state;
    return (
      <div className="item-title-container">
        <Row>
          <Col>
              <h1>Editing: {title}</h1>
          </Col>
        </Row>
      </div>
    )
  }
  renderActionBar() {
    const { id } = this.props.match.params;
    const { isEditorLoaded } = this.state;
    return (
      <div className="actionbar">
        <Row justify="space-between" align="middle">
          <Col>
            <div className="actionbar-links">
              <Link to="/list">
                <Button className="margin-r actionbar-link hide-on-mob" type="text" title="See content list" icon={<FolderAddOutlined />}>List</Button>
                <Button className="margin-r actionbar-link show-on-mob" type="text" title="See content list" size="large" icon={<FolderAddOutlined />} />
              </Link>
              <Link to={`/preview/${id}`}>
                <Button className="margin-r actionbar-link hide-on-mob" type="text" title="Preview content" icon={<EyeOutlined />}>View</Button>
                <Button className="margin-r actionbar-link show-on-mob" type="text" title="Preview content" size="large" icon={<EyeOutlined />} />
              </Link>
              <Link to={`/edit/${id}`}>
                <Button className="margin-r actionbar-link hide-on-mob  actionbar-link-active" type="text" title="Edit content" icon={<EditOutlined />}>Edit</Button>
                <Button className="margin-r actionbar-link actionbar-link-active show-on-mob" type="text" title="Edit content" size="large" icon={<EditOutlined />} />
              </Link>
            </div>
          </Col>
          <Col>
            <div className="actionbar-controls">
              <Link to="/list">
                <Button className="margin-l hide-on-mob" title="Return to content list">Cancel</Button>
                <Button className="margin-l show-on-mob" title="Return to content list" icon={<AiOutlineCloseCircle />}></Button>
              </Link>
              <Button disabled={!isEditorLoaded} className="margin-l hide-on-mob" type="primary" onClick={this.onClickSave} title="Save content">Save</Button>
              <Button disabled={!isEditorLoaded} className="margin-l show-on-mob" type="primary" onClick={this.onClickSave} title="Save content" icon={<AiFillSave />}></Button>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  //render
  render() {
    const { contentService, token } = this.props;
    const { id } = this.props.match.params;
    const { isEditorLoaded, tags } = this.state;

    return (
      <>
        <Row justify="center">
          <Col xs={24} sm={24} md={22} lg={18} xl={16}>
            <div className="panel-body">

              { this.renderActionBar() }

              <div className="cm-h5p-container">
                <div className="cm-h5p-inner-container">

                  <LoaderHidden />

                  <div className="cm-h5p-content-container">
                    { this.renderTitle() }

                    { isEditorLoaded && <Tags className="margin-b" value={tags} onChange={this.onChangeTags} /> }

                    <H5PEditorUI
                      ref={this.h5pEditorRef}
                      contentId={id}
                      loadContentCallback={ async (contentId) => {
                        log('[H5PEditorUI] loadContentCallback');
                        //load item
                        const res = await contentService.getEdit(contentId, token);
                        //write tags into state
                        if (res.tags) this.setState({ tags : res.tags });
                        //add item title to state
                        if (res.metadata?.title) this.setState({title: res.metadata.title});
                        //return loaded item
                        return res;
                      }}
                      saveContentCallback={ async (contentId, requestBody) => {
                        log('[H5PEditorUI] saveContentCallback');
                        //attach tags to request body
                        requestBody.tags = tags;
                        //save item
                        return await contentService.save(contentId, requestBody, token);
                      }}
                      onSaved={this.onSaved}
                      onLoaded={this.onEditorLoaded}
                      onSaveError={this.onSaveError}
                    /> 
                  </div>
                  
                </div>
              </div>

              { /* only render second action bar once editor has completed loading */ }
              { isEditorLoaded && this.renderActionBar() }
             
            </div>
          </Col>
        </Row>
      </>
    )
  }

  //handlers
  onChangeTags = tags => this.setState( {tags} );
  onClickSave = async () => {
    log(`[PageEdit] onClickSave`);
    try {
      await this.h5pEditorRef.current?.save();
    } catch (err) {
      //ignore error, already listening for 'save-error' and 'validation-error' events below
    }
  }
  onSaveError = (msg) => {
    log(`[PageEdit] onSaveError`);
    log(msg);
    message.error(msg);
  };
  onSaved = async (id) => {
    log(`[PageEdit] onSaved`);
    message.success({ content: 'Item saved' });
    //go to preview
    setTimeout(() => {
      this.props.history.push(`/preview/${id}`);
    }, 500);
  };
  onEditorLoaded = () => { //triggered after content type picked and editor is actually loaded
    log(`[PageEdit] onEditorLoaded`);
    this.setState({ isEditorLoaded: true, isLoading: false });
  };
}

//global state to props
const mapStateToProps = (state) => ({
  token: state.user.token,
});

//Wrap page in withRouter so it picks up content type id for props
export default withRouter( connect(mapStateToProps)(PageEdit) );