//Antd
import { Col, Input, Row } from 'antd';
import { AiOutlineTag } from 'react-icons/ai';
   
export const Tags = (props) => {
  
  const { value, onChange } = props;

  const onChangeText = (e) => {
    //check if onChange supplied/exists and run
    if (onChange) onChange(e.target.value);
  }

  return (
    <div className="margin-b">
      <Row>
        <Col span={24}>
          <Input onChange={onChangeText} aria-label="Enter comma separated tags" addonBefore={<span title="Optional: Add comma separated tags to help organise your content"><AiOutlineTag /> Tags</span>} allowClear={true} size="large" placeholder="Optional: Add comma separated tags to help organise your content" title="Optional: Add comma separated tags to help organise your content" value={value}></Input>
        </Col>
      </Row>
    </div>
  )
}